import Vue from "vue";
import VModal from "vue-js-modal";
import vuetify from '@/plugins/vuetify'
import { BootstrapVue } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import index from "./store";
import router from "./router";

import App from "./App.vue";
import Vue2MultipleVModels from "vue2-multiple-vmodels";
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyASmUAAveXHJWabL76ncq8D2khhs7CEQ4w',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  }
})


  Vue.use(Vue2MultipleVModels);

Vue.config.productionTip = false;

Vue.use(VModal);
Vue.use(BootstrapVue)

new Vue({
  render: h => h(App),
  router,
  store: index,
  vuetify,
}).$mount("#app");
