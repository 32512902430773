<template>
  <div>
    <AppHeader :key="appHeaderKey" :callBack="reRenderRouterView" />
    <div class="container mx-auto">
      <div>
        <div class="row" style="display: flex; flex-direction: row; justify-content: space-between; margin-left: 10%">
          <div>
            <h1 class="font-bold mt-8" style="font-size: 3.25em">Checkout (1 item)</h1>
            <p class="text-gray-700 mb-5">
              Please provide the following information to complete your purchase
            </p>
          </div>
          <div style="margin-right: 10%">
            <router-link to="/cart">
              <h2 class="text-blue-500 text-right mt-10">Return to cart</h2>
            </router-link>
          </div>
        </div>
        <div class="row bg-blue-100" style="margin-left: 10%;">
          <div class="col-md-5">
            <h2 class="font-black" style="font-size: 2.5em; margin-top: 10%">Contact Information</h2>
            <v-card
                elevation="2"
            >
              <v-card-text>
                <div>
                  <div class="mb-4" style="display: flex; flex-direction: row; justify-content: space-between">
                    <h2 class="font-black text-black">Contact</h2>
                    <h2 style="float: right">usercontact@gmail.com</h2>
                  </div>
                  <div class="mb-4" style="display: flex; flex-direction: row; justify-content: space-between">
                    <h2 class="font-black text-black">Name</h2>
                    <h2 style="float: right">John Doeray</h2>
                  </div>
                  <div class="mb-4" style="display: flex; flex-direction: row; justify-content: space-between">
                    <h2 class="font-black text-black">Address</h2>
                    <h2 style="float: right">
                      1234, Address name,<br/>
                      City here, ZIP
                    </h2>
                  </div>
                  <div class="mb-4" style="display: flex; flex-direction: row; justify-content: space-between">
                    <h2 class="font-black text-black">City, State</h2>
                    <h2 style="float: right">Las Vegas, Nevada</h2>
                  </div>
                  <div class="mb-4" style="display: flex; flex-direction: row; justify-content: space-between">
                    <h2 class="font-black text-black">Country</h2>
                    <h2 style="float: right">United States</h2>
                  </div>
                  <div class="mb-3" style="display: flex; flex-direction: row; justify-content: space-between">
                    <h2 class="font-black text-black">Zip/Postal</h2>
                    <h2 style="float: right">9128093</h2>
                  </div>
                  <div class="mb-3" style="display: flex; flex-direction: row; justify-content: space-between">
                    <h2 class="font-black text-black">Phone</h2>
                    <h2 style="float: right">+1 (0) 9128093</h2>
                  </div>
                </div>
              </v-card-text>
              <v-divider class="mx-4"></v-divider>
            </v-card>
            <div class="justify-content-between mt-4">
              <v-btn
                  dark
                  color="deep-orange"
              >
                Pickup In-Store
              </v-btn>
              <router-link to="/order/checkout-1">
                <v-btn depressed>
                  Delivery Services
                </v-btn>
              </router-link>
            </div>
            <div class="mt-5 mb-5">
              <router-link to="/microsite">
                <v-btn>
                 <span class="flex items-center">
                      <svg
                          style="color: blue"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16" height="16" fill="currentColor"
                          class="bi bi-chevron-left"
                          viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                              fill="blue"></path>
                      </svg>
                 </span>
                  Return to Microsite
                </v-btn>
              </router-link>
            </div>
          </div>
          <div class="col-md-4 justify-center ml-56">
            <h1 style="font-size: 1.5rem; font-weight: bold; color: black; margin-top: 0.5em">Order Summary</h1>
            <template>
              <v-card
                  class="mx-auto my-12"
                  max-width="374"
              >
                <v-img
                    class="mt-2"
                    height="200"
                    width="90%"
                    style="margin-left: 5%"
                    src="https://images.unsplash.com/photo-1541280910158-c4e14f9c94a3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80"
                ></v-img>

                <v-card-text>
                  <v-row
                      align="center"
                      class="mx-0 mt-3"
                  >
                    <div>
                      <h2 style="font-size: 1.20rem; font-weight: bolder">
                        Product Description
                      </h2>
                      <p>
                        Lenscrafters get 1 Free Lens craters get 1 <br/>Free Lens crafters get 1 Free
                      </p>
                    </div>
                  </v-row>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <div>
                    <div style="display: flex; flex-direction: row; justify-content: space-between">
                      <h2 class="font-black">Subtotal</h2>
                      <h2 style="float: right">$24.55</h2>
                    </div>
                    <div style="display: flex; flex-direction: row; justify-content: space-between">
                      <h2 class="font-black">Shipping</h2>
                      <h2 style="float: right">Calculated at next step</h2>
                    </div>
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <div>
                    <div class="mt-4" style="display: flex; flex-direction: row; justify-content: space-between">
                      <h2>Total</h2>
                      <span>
                      <h3 class="text-right">
                        $24.55
                        <br />
                         <span style="color: forestgreen; font-weight: bolder">You save $24.55</span>
                      </h3>
                    </span>
                    </div>
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <router-link to="/order/checkout/payment">
                  <v-card-actions>

                    <v-btn
                        dark
                        color="deep-orange"
                        block>
                      Proceed to Checkout
                    </v-btn>
                  </v-card-actions>
                </router-link>
              </v-card>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/layout/Header";

export default {
  name: "Final",
  components:{
    AppHeader: Header
  },
  data () {
    return{
      appHeaderKey: Math.random() + 1,
    }
  },
  methods: {
    reRenderRouterView() {
      this.routerViewKey++;
    },
    showLocationModal() {
      this.dialog = true;
    },
    hideLocationModal() {
      this.dialog = false;
    },
    reRenderAppheader() {
      this.appHeaderKey++;
    },
  }
}
</script>

<style scoped>

</style>
