<template>
  <div>
    <div class="container mx-auto pt-4 pb-8">
      <div class="flex mt-4 space-x-8">
        <div class="relative h-full sm:w-2/3 w-1/3 bg-white p-6 border" style="height:500px">
          <div>
            <div class="p-4 border-2 border-gray-200">
              <div class="py-1 flex flex-row justify-between items-center">
                <div class="block">
                  <label style="width: max-content; min-width: 100px"
                    >Contact</label
                  >
                  <span v-if="!editContact" class="ml-4"
                    >something@gmail.com</span
                  >
                </div>
                
              </div>

              <div class="py-1 flex flex-row justify-between items-center">
                <div>
                  <label style="width: max-content; min-width: 100px"
                    >Ship To</label
                  >
                  <span v-if="!editShipTo" class="ml-4"
                    >Shipping adress info goes here</span
                  >
                </div>
              </div>

            </div>
              <div class="py-4">

              <label>Shipping Method</label>
                  <select id="shippingmethod"
                                   class="w-full my-2 p-1 bg-white border-2 focus:outline-none focus:ring focus:border-blue-300 border-gray-200"
                    >
                  <option value="">Standard</option>
                </select>
                </div>            
            <div class="w-full my-2 flex flex-row justify-between">
              <router-link to="/retailers/retailer">
                <button class="border-2 border-gray-200 p-1 my-2">
                  Return to retailer's page
                </button>
              </router-link>
              <router-link to="/payment" >
              <button
                class="ml-2 border-2 border-gray-200 p-1 bg-burning-orange-500 hover:bg-burning-orange-600"
              >
                Pay Now
              </button>
              </router-link>
            </div>
          </div>
        </div>
        <div
          class="flex-auto flex flex-col bg-white border max-h-full overflow-hidden" style="height:500px"
        >
          <div class="p-4">
            <img
              :src="getImageUrl('coupon-img-lenscrafters@2x.png')"
              alt="Versace"
            />
          </div>
          <div class="px-4 mt-2 overflow-y-auto">
            <p class="font-semibold py-2">Product Description</p>
            <hr />
            <div class="my-4">
              <div class="flex flex-row justify-between">
                <p class="font-semibold">Subtotal</p>
                <p class="font-semibold">$24.95</p>
              </div>
              <div class="flex flex-row justify-between">
                <p class="font-semibold">Subtotal</p>
                <p class="font-semibold">Calculated at next step</p>
              </div>
            </div>
            <hr />
            <div class="my-6 flex flex-row justify-between">
              <p class="font-semibold">Total</p>
              <p class="font-semibold">$24.95</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>
<style scoped>
</style>
<script>
import HelpersMixin from "@/mixins/Helpers";

export default {
  mixins: [HelpersMixin],
  name: "state",

  data: function () {
    return {
      selectedCountry: {},
      editContact: false,
      editShipTo: false,
      states: {},
    };
  },
  methods: {
    setStates: function (s) {
      this.states = s;
      console.log(s);
    },
    editContactAddress: function (editContactAddress) {
      this.editContact = editContactAddress;
    },
    editShip: function (editShip) {
      this.editShipTo = editShip;
    },
    onChange(event) {
      console.log(event.target.value);
    },
  },
  computed: {
    countries() {
      return this.$store.state.countries;
    },
  },
};
</script>

