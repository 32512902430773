<template>
  <div>
    <div class="container mx-auto pt-4 pb-8">
      <div class="flex mt-4 space-x-8" style="height: 500px">
        <div
          class="relative h-full sm:w-2/3 w-1/3 bg-white flex items-center justify-center flex-shrink-0 border"
        >
          <img
            :src="getImageUrl('coupon-img-lenscrafters@2x.png')"
            alt="Versace"
          />
        </div>
        <div
          class="flex-auto flex flex-col bg-white border max-h-full overflow-hidden"
        >
          <div class="p-4">
            <h2
              class="text-xl sm:text-base sm:font-normal font-semibold leading-tight"
            >
              Most stylish lens out there, with exclusive tech perks and state
              of the art features
            </h2>
            <div class="flex justify-between items-baseline mt-2">
              <p class="font-semibold">
                <span class="text-burning-orange-500">By:</span> LensCrafters
              </p>
            </div>
            <div class="flex justify-between items-baseline mt-2">
              <p class="font-semibold">
                <span class="text-4xl">$9.99</span>
              </p>
            </div>
          </div>
          <div
            class="bg-gray-200 flex items-center justify-center space-x-1 py-2 border-t border-b"
          >
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <p class="font-semibold">
              <span class="text-burning-orange-500">Offer Expires:</span>
              <span class="ml-2">12/31/2020</span>
            </p>
          </div>
          <div class="bg-gray-dark px-4 py-3">
            <router-link to="/shippinginfo">
              <button
                class="block w-full px-6 py-2 text-xs bg-burning-orange-500 text-white font-semibold uppercase rounded hover:bg-burning-orange-600"
                type="button"
              >
                BUY NOW
              </button>
            </router-link>
          </div>
          <div class="p-4 overflow-y-auto">
            <p class="font-semibold">Company Details</p>
            <p class="mt-2">
              Founded in 1983, LensCrafters pioneered a revolutionary concept to
              combine eye care, eyewear and onsite labs to craft glasses in
              about an hour. Today, LensCrafters is one of the largest optical
              retail brands in North America in terms of sales.
              <br />
              Most LensCrafters stores are located in high-traffic commercial
              malls and shopping centers. A wide selection of premium and luxury
              optical frames, sunglasses and high-quality lenses and optical
              products made by Luxottica and other suppliers are available in
              most locations. Each location has an experienced doctor, either an
              independent or employed doctor of optometry, who is focused on
              building patient relationships. All of the stores have access to
              the Group's state-of-the-art lens processing network with the
              ability to craft, surface, finish and fit lenses. <br />
              As part of its underlying commitment to customer satisfaction and
              industry innovation, LensCrafters has made significant investments
              in technology including AccuFit Digital Measurement™ and continued
              its digital transformation with associate iPads to enhance the
              customer’s omnichannel experience, and a digital eye exam
              experience, ClarifyeSM, in a growing number of locations. <br />
              In 2006, Luxottica began to expand the LensCrafters brand in China
              by acquiring and then rebranding local retail chains in Beijing,
              Shanghai, Guangdong and Hong Kong. As of December 31, 2018, the
              Group operated a retail network of 1,158 LensCrafters stores, of
              which 1,050 are in North America and the other 108 stores are in
              China and Hong Kong.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white py-12">
      <div class="container mx-auto">
        <h3 class="text-2xl text-center mt-10">
          <strong>More Amazing</strong> Offers
        </h3>
        <p class="text-gray-600 text-center">
          Discover discount savings from top retailers.
        </p>
        <hr class="mt-10" />

        <div
          class="grid md:grid-cols-3 xl:grid-cols-4 grid-cols-2 gap-2 md:gap-8 py-6"
        >
          <div v-for="i in 8" :key="i" class="space-y-4">
            <div class="border p-12">
              <img
                src="https://images-na.ssl-images-amazon.com/images/I/71NqrR6KJ6L._AC_SX679._SX._UX._SY._UY_.jpg"
                alt="Hair"
              />
            </div>
            <div class="font-semibold space-y-1">
              <p>Save up to 25% on sunglasses</p>
              <p>
                <span class="text-burning-orange-500">By: </span>
                <span class="text-gray-600">LensCrafters</span>
              </p>
              <p
                class="flex items-center justify-center space-x-1 py-1 border-2 border-burning-orange-500 rounded"
              >
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  ></path>
                </svg>
                <span class="text-burning-orange-500">Expires:</span>
                <span>12/31/2020</span>
              </p>
              <router-link to="/retaileroffer">
                <button
                  type="button"
                  class="block w-full py-2 my-1 bg-gray-dark hover:bg-gray-darker text-white font-semibold rounded"
                >
                  Get Deal
                </button>
              </router-link>
            </div>
          </div>
        </div>
        <div class="text-center mt-10">
          <a
            href="/offers"
            class="inline-flex items-center space-x-2 text-gray-700 hover:text-black"
          >
            <span class="font-semibold">View All Offers</span>
            <svg
              class="w-6 h-6 text-burning-orange-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>
<style scoped>
</style>
<script>
import HelpersMixin from "@/mixins/Helpers";

export default {
  mixins: [HelpersMixin],
};
</script>
