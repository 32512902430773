<template>
    <v-slide-group
      show-arrows
      multiple style="width: 1300px ">

    <v-slide-item
          v-for="item in items"
          :key="item.title"
      >
      <v-container>
        <v-row>
          <v-col class="col-md-3">
            <v-card
                outlined
                max-width="364px"
            >
              <div class="offer-img">
                <img src="../assets/svg/featured/featured-tab.svg" alt="img" style="width: 15%">
                <v-img :src="item.avatar" style="width: 300px; height: 160px; margin: 14px 0 0 41px; object-fit: contain;"></v-img>
              </div>
              <v-card-text
                  class="featured-container" style="font-size: 18px; font-weight: bold; font-stretch: normal; font-style: normal;
                line-height: 1.22; letter-spacing: normal; text-align: left;">
                <h2 class="text-white font-black">
                  {{ item.title }}
                </h2>
                <h2 class="text-white">
                  {{ item.description }}
                </h2>
                <div class="flex flex-row" style="justify-content: space-between">
                  <div>
                    <h2 class="text-orange-500 font-black">
                      Expires:
                      <span class="text-white">{{ item.expiry }}</span>
                    </h2>
                  </div>
                  <div>
                    <h2 class="text-orange-500 font-black">
                      By:
                      <span class="text-white">{{ retailer }}</span>
                    </h2>
                  </div>
                </div>
                <button
                    @click="getDeal(item.title, item.price,item.avatar, item.description, item.expire_date, item.discount, retailer)"
                    class="btn_bg text-white w-full py-2 fw-bold"
                >
                </button>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      </v-slide-item>
  </v-slide-group>
 
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "FeaturedCarousel",
  data() {
    return {
      retailer: 'lenscrafters',
      items: [
        {
          title: 'Versace Eyeglsses',
          avatar: require('../assets/svg/featured/img-ft-offer-2.svg'),
          discount: '40%',
          price: '24.00',
          expiry: '10/08/22',
          description: "40% off lenses with frame purchase"
        },
        {
          title: 'Versace Eyeglases',
          avatar: require('../assets/svg/featured/img-ft-offer-2.svg'),
          discount: '40%',
          expiry: '10/08/22',
          price: '24.00',
          description: "40% off lenses with frame purchase"
        },
        {
          title: 'Rayban Eyeglass',
          avatar: require('../assets/svg/featured/Image-9.svg'),
          discount: '40%',
          expiry: '10/08/22',
          price: '24.00',
          description: "40% off lenses with frame purchase"
        },
        {
          title: 'Rayban Eyeglases',
          avatar: require('../assets/svg/img-ft-offer-1.svg'),
          discount: '40%',
          price: '24.00',
          expiry: '10/08/22',
          description: "40% off lenses with frame purchase"
        },
        {
          title: 'Rayban Eglasses',
          avatar: require('../assets/svg/featured/Image-15.svg'),
          discount: '40%',
          price: '24.00',
          expiry: '10/08/22',
          description: "40% off lenses with frame purchase"
        },
        {
          title: 'Rayban Eyeglaes',
          avatar: require('../assets/svg/featured/Image-19.svg'),
          discount: '40%',
          price: '24.00',
          expiry: '10/08/22',
          description: "40% off lenses with frame purchase"
        },
        {
          title: 'Rayban eglasses',
          avatar: require('../assets/svg/featured/Image-19.svg'),
          discount: '40%',
          price: '24.00',
          expiry: '10/08/22',
          description: "40% off lenses with frame purchase"
        },
      ],
    }
  },
  methods: {
    getDeal(title,price, avatar, description, expiry, discount, retailer) {
      this.$router.push({name: 'InStore', params: {
          title: title,
          price: price,
          image: avatar,
          description: description,
          expiry: expiry,
          discount: discount,
          retailer: retailer
        }})
    },
    formatDate(date) {
      return moment(date).format('M/d/YYYY');
    },
    getFeaturedCoupons() {
      axios({
        method: "get",
        url: 'http://18.237.231.25:5000/api/coupons',
      })
          .then((response) => {
            this.coupons = response.data.slice(0, 6);
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
            this.loading = false;
          });
    },
  },
  mounted() {
    this.getFeaturedCoupons();
  }
}
</script>

<style scoped>
.featured-container{
  width: 364px;
  height: 168px;
  padding: 16px 20px 19.4px;
  background-color: #2e2e2e;
}
.btn_bg {
  background-image: url("../assets/svg/getdeal.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 324px;
  height: 40px;
  margin: 18px 0 0;
}
.offer-img{
  width: 364px;
  height: 240px;
  padding: 0.6px 12px 24.4px 1px;
  border: solid 1px #e5e5e5;
  background-color: #fff;
}
.offer-button{
  width: 324px;
  height: 40px;
  margin: 18px 0 0;
  padding: 9px 0 0 10px;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: center;
  border-radius: 4px;
}
v-slide-group{
  margin-left: 20%; margin-right: auto; width: max-content;
}
</style>
