// src/plugins/vuetify.js

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
          light: {
            primary: '#fff',
            secondary: '#333',
            bgone: '#f6f6f8',
            accent: '#FF7433',
            error: '#b71c1c',
          },
        },
      },
      icons: {
        iconfont: 'mdi', // default - only for display purposes
      },
}

export default new Vuetify(opts)