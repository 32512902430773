<template>
  <div :class="[onModal? 'flex flex-col': 'flex flex-col h-screen']">
    <div :class="[onModal? 'hidden': 'bg-gray-darker']">
      <div class="container mx-auto">
        <div class="flex justify-between items-center py-2">
          <div class="flex-shrink-0 py-2">
            <router-link to="/" class="text-white text-2xl">
              <img
                class="w-48"
                src="@/assets/images/dashshop_Logo.png"
                alt="Dash Shop"
              />
            </router-link>
          </div>
          <div class="text-gray-400 text-xs">
            <ul class="flex space-x-4">
              <li>
                <router-link to="/" class="hover:text-white">Cart</router-link>
              </li>
              <li>
                <router-link to="/" class="hover:text-white">
                  Submit a Coupon
                </router-link>
              </li>
              <li>
                <router-link to="/" class="hover:text-white">
                  How It Works
                </router-link>
              </li>
              <li>
                <router-link to="/signup" class="hover:text-white">
                  Sign Up
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1 bg-gray-100">
      <div class="w-full max-w-xl mx-auto py-12 space-y-6">
        <h2 class="text-2xl text-center">
          <strong>Sign In</strong> to discover discount offers!
        </h2>
        <div :class="[onModal?'hidden': 'flex justify-between py-4']">
          <router-link
            to="/login"
            class="
              block
              flex-1
              uppercase
              py-4
              border-b-4
              text-center
              border-burning-orange-500
              hover:bg-gray-200
            "
          >
            I have an account
          </router-link>
          <router-link
            to="/signup"
            class="
              block
              flex-1
              uppercase
              py-4
              border-b-4
              text-center
              hover:bg-gray-200
            "
          >
            I'm a new customer
          </router-link>
        </div>
        <form>
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail*"
            required
            placeholder="fill email"
            class="form-input form-control"
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Password*"
            :rules="passwordRules"
            type="password"
            placeholder="password"
            class="form-control"
            required
          ></v-text-field>
          <div class="mt-4">
            <label htmlFor="keepSignedIn" class="flex space-x-4">
              <input type="checkbox" name="keepSignedIn" id="keepSignedIn" />
              <span>Keep me signed in on this computer</span>
            </label>
          </div>
          <div class="mt-8" data-app>
            <v-dialog
                transition="dialog-top-transition"
                max-width="700"
                style="stop-opacity: 1"
            >
              <template v-slot:activator="{ on, attrs }">
                <button
                    v-bind="attrs"
                    v-on="on"
                    type="button"
                    class="
                block
                w-full
                py-3
                bg-burning-orange-500
                text-white
                font-semibold
                rounded
                hover:bg-burning-orange-600
              "
                >
                  Sign In
                </button>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <div
                      class="flex flex-row justify-between"
                  >
                    <div>
                      <img
                          src="../../assets/images/corner1.png"
                          width="30%"
                          alt=""/>
                    </div>
                    <v-btn
                        class="mx-2 mt-2 text-center"
                        x-small
                        elevation="0"
                        @click="dialog.value = false"
                    >
                      <v-icon dark>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </div>
                  <v-card-text class="justify-center align-middle" style="margin-top: -15%">
                    <div class="text-h2 pa-12 justify-center">
                      <v-img :src="delivery.logo" width="150px" style="margin-left: 35%"></v-img>
                    </div>
                  </v-card-text>
                  <v-card-text>
                    <h2 style="font-weight: bolder; font-size: 1.5em; text-align: center; color: black">
                      {{delivery.description}}
                    </h2>
                  </v-card-text>
                  <v-card-actions class="justify-between">
                    <div>
                      <div class="justify-items-center justify-center">
                        <img src="../../assets/svg/exrract/1.svg" width="25" height="25" alt="1" style="margin-left: 40%;margin-bottom: 10%" />
                      </div>
                      <v-btn
                          dark
                          color="deep-orange"
                          style="background-color: #ff6f3a"
                          elevation="2"
                          @click="dialog2 = !dialog2"
                      >
                        <span class="mr-2"><img src="../../assets/svg/exrract/scan.svg" style="color: black" alt="scanner"></span>
                        Scan QR
                      </v-btn>
                      <v-dialog
                          v-model="dialog2"
                          max-width="600px"
                      >
                        <v-card>
                          <div
                              class="flex flex-row justify-between"
                          >
                            <div>
                              <img
                                  src="../../assets/images/corner1.png"
                                  width="30%"
                                  alt=""/>
                            </div>
                            <v-btn
                                class="mx-2 text-center"
                                x-small
                                elevation="0"
                                @click="dialog.value = false"
                            >
                              x
                            </v-btn>
                          </div>
                          <v-card-text style="margin-top: -10%">
                            <div class="row">
                              <div class="col-md-6">
                                <img src="../../assets/qrcode.png" alt="qr" width="300" height="300">
                                <div class="flex flex-row mt-8 ml-10">
                                  <img src="../../assets/svg/scan.svg" alt="scanner">
                                  <h2 class="ml-4 text-center">
                                    Check Your Mobile
                                  </h2>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <h2 class="font-bold text-black">
                                  Use your device to scan your coupon QR & and use in-store to receive savings
                                </h2>
                                <div class="mt-7">
                                  <h2 class="text-black font-bold">
                                    Show this coupon in-store to <span class="text-orange-500">Redeem Coupon</span>
                                  </h2>
                                </div>
                                <div>
                                  <div class="input-group mb-3">
                                    <input type="text" class="form-control" v-model="delivery.coupon" aria-label="Recipient's username" aria-describedby="basic-addon2">
                                    <div class="input-group-append" style="background-color: #ff6f3a">
                                        <span class="input-group-text border-0" id="basic-addon3" style="background-color: #ff6f3a">
                                          <v-img :src="delivery.tag" ></v-img>
                                        </span>
                                    </div>
                                  </div>
                                </div>
                                <h2 class="text-black font-black mb-2">Details</h2>
                                <div class="bg-blue-100">
                                  <div class="flex flex-row">
                                    <img src="../../assets/svg/clock.svg" alt="">
                                    <h2 class="font-black text-black">
                                       <span class="text-orange-500 ml-3 font-black">
                                        Expires
                                       </span>
                                      {{delivery.expiry}}
                                    </h2>
                                  </div>
                                </div>
                                <v-divider></v-divider>
                                <div>
                                  <h2 class="font-black text-black">
                                    <span class="text-orange-500 ml-3 font-black">Coupon Instructions</span>
                                    Show this coupon at checkout to get an extra 56% off
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </v-card-text>

                        </v-card>
                      </v-dialog>
                    </div>
                    <div>
                      <div class="justify-items-center justify-center">
                        <img src="../../assets/svg/exrract/2.svg" width="25" height="25" alt="1" style="margin-left: 40%;margin-bottom: 10%" />
                      </div>
                      <v-btn
                          dark
                          color="deep-orange"
                          style="background-color: #ff6f3a"
                          elevation="2"
                          @click="dialog4 = !dialog4"
                      >
                        <span class="mr-2"><img src="../../assets/svg/exrract/email.svg" alt="scanner"></span>
                        Send as Email
                      </v-btn>
                      <v-dialog
                          v-model="dialog4"
                          max-width="650px"
                      >
                        <v-card>
                          <div
                              class="flex flex-row justify-between"
                          >
                            <div>
                              <img
                                  src="../../assets/images/corner1.png"
                                  width="30%"
                                  alt=""/>
                            </div>
                            <v-btn
                                class="mx-2 text-center"
                                x-small
                                elevation="0"
                                @click="dialog.value = false"
                            >
                              x
                            </v-btn>
                          </div>
                          <v-card-text style="margin-top: -17%">
                            <div class="row justify-center">
                              <h2 class="text-center text-black font-black">We have successfully sent the coupon below to your email address</h2>
                            </div>
                            <div class="row">
                              <div class="col-md-6" style="border: 1px dashed black; justify-content: center">
                                <img :src="product.image" alt="qr" style="margin-left: 15%; width: 250px">
                                <h2 class="text-center mt-3 text-black font-black" style="font-size: 1.2em">
                                  {{product.description}}
                                </h2>
                                <h1 class="text-orange-500 font-bold text-center mt-3" style="font-size: 1.5em">
                                  {{product.discount}}
                                </h1>
                                <div class="flex mt-3 flex-row justify-between">
                                  <h2 class="text-black font-black">
                                    Expires
                                    <span class="text-orange-500 ml-3">
                                        {{product.expires}}
                                      </span>
                                  </h2>
                                  <h2 class="text-black font-black">
                                    By
                                    <span class="text-orange-500 ml-3">
                                        {{product.by}}
                                      </span>
                                  </h2>
                                </div>
                                <div class="mt-3">
                                  <h2 class="text-center text-black">
                                    {{product.other}}
                                  </h2>
                                </div>
                                <div class="mt-12">
                                  <h2 class="text-orange-500 text-center font-bold" style="font-size: 1.2em">
                                    Coupon Code:
                                    <span class="text-black">{{delivery.coupon}}</span>
                                  </h2>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <v-img :src="delivery.logo" alt="logo" width="200" class="ml-10" />
                                <div class="mt-5">
                                  <h2 class="text-black font-bold" style="font-size: 0.85em">
                                    Show this coupon in-store to <span class="text-orange-500">Redeem Coupon</span>
                                  </h2>
                                </div>
                                <div>
                                  <div class="input-group mb-3">
                                    <input type="text" class="form-control" v-model="delivery.coupon" aria-label="Recipient's username" aria-describedby="basic-addon2">
                                    <div class="input-group-append" style="background-color: #ff6f3a">
                                        <span class="input-group-text border-0" id="basic-addon2" style="background-color: #ff6f3a">
                                          <v-img :src="delivery.tag" ></v-img>
                                        </span>
                                    </div>
                                  </div>
                                </div>
                                <div style="margin-top: 5%; justify-content: center">
                                  <h3 class="text-black font-bold" style="font-size: 0.8em">
                                    Or <span class="text-orange-500">scan</span> QR code to receive <span class="text-orange-500">Discount Savings</span>
                                  </h3>
                                  <div class="justify-center" style="margin-left: 25%; margin-top: 5%">
                                    <img src="../../assets/svg/qr-image.svg" class="center" alt=""/>
                                  </div>
                                  <div class="flex flex-row mt-4 justify-center">
                                    <img src="../../assets/svg/scan.svg" alt="scanner">
                                    <h2 class="ml-4 text-center">
                                      Scan QR
                                    </h2>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <v-divider></v-divider>
                            <div class="mt-4 flex flex-row">
                              <img src="../../assets/printer.svg" alt="printer">
                              <div>
                                <h2 class="text-black font-bold ml-3">
                                  Print
                                </h2>
                                <h3 class="text-black font-bold ml-3">Print coupon or scan QR code and show to cashier to redeem coupon</h3>
                              </div>

                            </div>
                            <div class="mt-4">
                              <div>
                                <h2 class="text-black font-black mb-2">Details</h2>
                              </div>
                              <div class="bg-blue-100">
                                <div class="flex flex-row">
                                  <img src="../../assets/svg/clock.svg" alt="">
                                  <h2 class="font-black text-black">
                                       <span class="text-orange-500 ml-3 font-black">
                                        Expires
                                       </span>
                                    {{delivery.expiry}}
                                  </h2>
                                </div>
                              </div>
                              <div>
                                <h2 class="font-black text-black">
                                  <span class="text-orange-500 ml-3 font-black">Coupon Instructions</span>
                                  Show this coupon at checkout to get an extra 56% off
                                </h2>
                              </div>
                            </div>
                          </v-card-text>

                        </v-card>
                      </v-dialog>
                    </div>
                    <div>
                      <div class="justify-items-center justify-center">
                        <img src="../../assets/svg/exrract/3.svg" width="25" height="25" alt="1" style="margin-left: 40%;margin-bottom: 10%" />
                      </div>
                      <v-btn
                          dark
                          color="deep-orange"
                          style="background-color: #ff6f3a"
                          elevation="2"
                          @click="dialog3 = !dialog3"
                      >
                        <span class="mr-2"><img src="../../assets/svg/exrract/mobile-phone.svg" alt="scanner"></span>
                        Send to Mobile
                      </v-btn>
                      <v-dialog
                          v-model="dialog3"
                          max-width="700px"
                      >
                        <v-card>
                          <div
                              class="flex flex-row justify-between"
                          >
                            <div>
                              <img
                                  src="../../assets/images/corner1.png"
                                  width="30%"
                                  alt=""/>
                            </div>
                            <v-btn
                                class="mx-2 text-center"
                                x-small
                                elevation="0"
                                @click="dialog.value = false"
                            >
                              x
                            </v-btn>
                          </div>
                          <v-card-text style="margin-top: -10%">
                            <div class="row">
                              <div class="col-md-6">
                                <img src="../../assets/svg/featured/mobile-phone-icon.svg" alt="qr" style="margin-left: 20%">
                                <div class="flex flex-row mt-8 ml-10">
                                  <img src="../../assets/svg/featured/mobile-icon.svg" alt="scanner">
                                  <h2 class="ml-4 text-center">
                                    Check Your Mobile Device
                                  </h2>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <h2 class="font-bold text-black">
                                  We have successfully sent your coupon offer to your mobile device, view the link to redeem your offer
                                </h2>
                                <div class="mt-7">
                                  <h2 class="text-black font-bold">
                                    Show this coupon in-store to <span class="text-orange-500">Redeem Coupon</span>
                                  </h2>
                                </div>
                                <div>
                                  <div class="input-group mb-3">
                                    <input type="text" class="form-control" v-model="delivery.coupon" aria-label="Recipient's username" aria-describedby="basic-addon2">
                                    <div class="input-group-append" style="background-color: #ff6f3a">
                                        <span class="input-group-text border-0" id="basic-addon3" style="background-color: #ff6f3a">
                                          <v-img :src="delivery.tag" ></v-img>
                                        </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <h2 class="text-black font-black mb-2">Details</h2>
                              <div class="bg-blue-100">
                                <div class="flex flex-row">
                                  <img src="../../assets/svg/clock.svg" alt="">
                                  <h2 class="font-black text-black">
                                       <span class="text-orange-500 ml-3 font-black">
                                        Expires
                                       </span>
                                    {{delivery.expiry}}
                                  </h2>
                                </div>
                              </div>
                              <v-divider></v-divider>
                              <div>
                                <h2 class="font-black text-black">
                                  <span class="text-orange-500 ml-3 font-black">Coupon Instructions</span>
                                  Show this coupon at checkout to get an extra 56% off
                                </h2>
                              </div>
                            </div>
                          </v-card-text>

                        </v-card>
                      </v-dialog>
                    </div>
                  </v-card-actions>
                  <v-card-text style="font-weight: bolder; color: black">
                    <h2>
                      Details
                    </h2>
                    <h3>
                      Expires
                      <span class="text-orange-500 ml-2">{{delivery.expiry}}</span>
                    </h3>
                    <h3>
                      No coupon code needed. Just scan
                    </h3>
                  </v-card-text>
                </v-card>
                <div style="background-color: transparent; box-shadow: none">
                  <img :src="delivery.banner" alt="banner" style="margin-top: 5%; background-color: transparent; box-shadow: none;" />
                </div>
              </template>
            </v-dialog>
          </div>
        </form>
        <div>
          <v-snackbar
            v-model="snackbar"
            top
            :content-class="this.snackbarBgColor"
          >
                  <div class="flex flex-row justify-between items-center">
            {{ loginMessage }}

            <v-btn
              color="pink"
              text
              @click="snackbar = false"
            >
              X
            </v-btn></div>
          </v-snackbar>
        </div>
      </div>
    </div>
    <div :class="[onModal? 'hidden':'bg-gray-darker text-white py-8']" >
      <div class="container mx-auto">
        <div class="flex justify-between items-center space-x-12">
          <div class="flex">
            <div class="w-48">
              <img
                class="w-full"
                src="@/assets/images/dashshop_Logo.png"
                alt="Dash Shop"
              />
            </div>
            <div class="max-w-sm ml-12">
              <p class="text-xs">
                We have our very own fleet of delivery vans. Your order will be
                packed with care at warehouse and delivered right to your door.
              </p>
              <p class="mt-4 text-xs">
                &copy; {{ new Date().getFullYear() }} DashCoupons. All Rights
                Reserved!
              </p>
            </div>
          </div>
          <div>
            <h3>Follow Us</h3>
            <ul class="flex space-x-6 mt-2">
              <!-- Twitter -->
              <li class="flex-shrink-0">
                <a
                  class="
                    flex
                    items-center
                    justify-center
                    h-8
                    w-8
                    rounded-full
                    border border-white
                  "
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    class="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M17.3 6.2c0 0.2 0 0.3 0 0.5 0 5-3.8 10.7-10.7 10.7 -2.1 0-4.1-0.6-5.8-1.7 0.3 0 0.6 0.1 0.9 0.1 1.8 0 3.4-0.6 4.7-1.6 -1.7 0-3-1.1-3.5-2.6 0.2 0 0.5 0.1 0.7 0.1 0.3 0 0.7 0 1-0.1C2.8 11.2 1.5 9.7 1.5 7.8c0 0 0 0 0 0 0.5 0.3 1.1 0.5 1.7 0.5 -1-0.7-1.7-1.8-1.7-3.1 0-0.7 0.2-1.3 0.5-1.9C3.9 5.5 6.7 7 9.9 7.2 9.8 6.9 9.8 6.6 9.8 6.3c0-2.1 1.7-3.8 3.8-3.8 1.1 0 2.1 0.5 2.8 1.2 0.9-0.2 1.7-0.5 2.4-0.9 -0.3 0.9-0.9 1.6-1.7 2.1 0.8-0.1 1.5-0.3 2.2-0.6C18.7 5.1 18.1 5.7 17.3 6.2z"
                      fill="#FFF"
                    />
                  </svg>
                </a>
              </li>
              <!-- Instagram -->
              <li
                class="
                  flex-shrink-0 flex
                  items-center
                  justify-center
                  h-8
                  w-8
                  rounded-full
                  border border-white
                "
              >
                <a
                  class="
                    flex
                    items-center
                    justify-center
                    h-8
                    w-8
                    rounded-full
                    border border-white
                  "
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                    class="w-4 h-4"
                  >
                    <path
                      d="M17.946 5.29a6.606 6.606 0 00-.418-2.185 4.412 4.412 0 00-1.039-1.594A4.412 4.412 0 0014.895.472a6.606 6.606 0 00-2.184-.418C11.75.01 11.444 0 9 0S6.25.01 5.29.054a6.606 6.606 0 00-2.185.418A4.412 4.412 0 001.51 1.511 4.412 4.412 0 00.472 3.105a6.606 6.606 0 00-.418 2.184C.01 6.25 0 6.556 0 9s.01 2.75.054 3.71a6.606 6.606 0 00.418 2.185 4.412 4.412 0 001.039 1.594 4.411 4.411 0 001.594 1.039 6.606 6.606 0 002.184.418C6.25 17.99 6.556 18 9 18s2.75-.01 3.71-.054a6.606 6.606 0 002.185-.418 4.602 4.602 0 002.633-2.633 6.606 6.606 0 00.418-2.184C17.99 11.75 18 11.444 18 9s-.01-2.75-.054-3.71zm-1.62 7.347a4.978 4.978 0 01-.31 1.67 2.98 2.98 0 01-1.708 1.709 4.979 4.979 0 01-1.671.31c-.95.043-1.234.052-3.637.052s-2.688-.009-3.637-.052a4.979 4.979 0 01-1.67-.31 2.788 2.788 0 01-1.036-.673 2.788 2.788 0 01-.673-1.035 4.978 4.978 0 01-.31-1.671c-.043-.95-.052-1.234-.052-3.637s.009-2.688.052-3.637a4.979 4.979 0 01.31-1.67 2.788 2.788 0 01.673-1.036 2.788 2.788 0 011.035-.673 4.979 4.979 0 011.671-.31c.95-.043 1.234-.052 3.637-.052s2.688.009 3.637.052a4.979 4.979 0 011.67.31 2.788 2.788 0 011.036.673 2.788 2.788 0 01.673 1.035 4.979 4.979 0 01.31 1.671c.043.95.052 1.234.052 3.637s-.009 2.688-.052 3.637zM9 4.378A4.622 4.622 0 1013.622 9 4.622 4.622 0 009 4.378zM9 12a3 3 0 113-3 3 3 0 01-3 3zm5.884-7.804a1.08 1.08 0 11-1.08-1.08 1.08 1.08 0 011.08 1.08z"
                      fill="#fff"
                    />
                  </svg>
                </a>
              </li>
              <!-- Facebook -->
              <li
                class="
                  flex-shrink-0 flex
                  items-center
                  justify-center
                  h-8
                  w-8
                  rounded-full
                  border border-white
                "
              >
                <a
                  class="
                    flex
                    items-center
                    justify-center
                    h-8
                    w-8
                    rounded-full
                    border border-white
                  "
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                    class="w-4 h-4"
                  >
                    <path
                      d="M17 1H3C1.9 1 1 1.9 1 3v14c0 1.1 0.9 2 2 2h7v-7H8V9.5h2v-2c0-2.2 1.2-3.7 3.8-3.7l1.8 0v2.6h-1.2C13.4 6.4 13 7.1 13 7.8v1.7h2.6L15 12h-2v7h4c1.1 0 2-0.9 2-2V3C19 1.9 18.1 1 17 1z"
                      fill="#FFF"
                    />
                  </svg>
                </a>
              </li>
              <!-- LinkedIn -->
              <li
                class="
                  flex-shrink-0 flex
                  items-center
                  justify-center
                  h-8
                  w-8
                  rounded-full
                  border border-white
                "
              >
                <a
                  class="
                    flex
                    items-center
                    justify-center
                    h-8
                    w-8
                    rounded-full
                    border border-white
                  "
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                    class="w-4 h-4"
                  >
                    <path
                      d="M1 6h4v13H1V6zM3 1C1.8 1 1 2 1 3.1 1 4.1 1.8 5 3 5c1.3 0 2-0.9 2-2C5 1.9 4.2 1 3 1zM14.6 6.2c-2.1 0-3.3 1.2-3.8 2h-0.1l-0.2-1.7H6.9C6.9 7.6 7 8.9 7 10.4V19h4v-7.1c0-0.4 0-0.7 0.1-1 0.3-0.7 0.8-1.6 1.9-1.6 1.4 0 2 1.2 2 2.8V19h4v-7.4C19 7.9 17.1 6.2 14.6 6.2z"
                      fill="#FFF"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import HelpersMixin from "@/mixins/Helpers";
export default {
  mixins: [HelpersMixin],
  data: function () {
    return {
      dialog2: false,
      dialog3: false,
      dialog4: false,
      delivery: {
        coupon: '148372',
        tag: require('../../assets/svg/exrract/tagicon.svg'),
        banner: require('../../assets/svg/ad.png'),
        logo: require('../../assets/svg/lenscrafters.svg'),
        description: 'LensCrafters Vision sale. 40% off Lenses with a purchase of eyeglasses',
        expiry: '10/11/19'
      },
      product:{
        image: require('../../assets/svg/img-ft-offer-1.svg'),
        description: 'LensCrafter Vision Day sale. 40% off Lens with a purchase of glasses',
        discount: '50% OFF',
        expires: '10/9/20',
        by: 'LensCrafter',
        other: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua'
      },
      snackbar: false,
      snackbarBgColor: 'bg-green-500',
      valid: true,
      email: null,
      mobileNumber: null,
      zipcode: null,
      password: "",
      confirmPassword: "",
      loading: false,
      showRules: false,
      loginMessage: "",
      token: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
    };
  },
  props:{
    onModal: Boolean,
    callBack: { type: Function }
  },
  methods: {
    LogIn() {
      if (this.valid) {
        this.loading = true;
        axios({
          method: "post",
          url: `http://localhost:5000/api/auth/login`,
          data: {
              "userName": this.email,
              "password": this.password
            }
        })
          .then((response) => {
            this.loading = false;
            this.snackbar = true;
            this.loginMessage = "successfully logged in";
            this.token = response.data.token;
            localStorage.setItem('token', response.data.token)
            localStorage.setItem('email', response.data.email)
            localStorage.setItem('userId', response.data.id)
            localStorage.setItem('zipCode', response.data.zip_code)
            this.onModal? this.callBack(): this.$router.push('/')
          })
          .catch((error) => {
            this.errored = true;
            this.loading = false;
            this.snackbar = true;
            this.loginMessage = `Error: ${error.response.data}`;
            this.snackbarBgColor = "bg-red-500"
          });
      } else {
        this.showRules = true;
      }
    },
  },
};
</script>
