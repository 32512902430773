<template>
  <div class="w-full max-w-xs space-y-8"  style="width: 265px; margin: 0 0 23px 50px">
    <div class="border">
      <h2 class="px-4 py-3 text-white bg-gray-darker font-semibold">
        Shop By
      </h2>
      <div>
        <v-list
        >
          <v-list-item
              v-for="(category, index) in categories"
              :key="index"
          >
            <v-list-item-avatar>
              <router-link v-bind:to="'/category/' + category.name">
                <v-img :src="getImageUrl(category.icon)" alt="icon" class="access-img"></v-img>
              </router-link>
            </v-list-item-avatar>

            <v-list-item-content style="margin-left: 17px">
              <router-link v-bind:to="'/category/' + category.name">
                <v-list-item-title class="text-black" v-text="category.name"></v-list-item-title>
              </router-link>
            </v-list-item-content>

            <v-list-item-action>
                <v-icon color="green lighten-1">mdi-chevron-right</v-icon>
            </v-list-item-action>

          </v-list-item>
        </v-list>
        <div class="flex justify-end items-center px-4 py-4">
          <a
              href="/category"
              class="flex items-center space-x-2 text-gray-700 hover:text-gray-900"
          >
            <span class="font-semibold secondary--text">View all Categories</span>
            <span class="flex items-center">
            <svg
                class="w-4 h-4 text-burning-orange-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </span>
          </a>
        </div>
      </div>
      <div class="px-4">
        <hr class="my-6"/>
      </div>
      <div>
        <h3 class="px-4 font-semibold mb-4">Price Slider</h3>
        <div class="flex flex-wrap items-center px-4 mt-2">
          <div id="app">
            <div class="track-container mt-4">
              <span class="range-value min">${{ minValue }} </span> <span class="range-value max">${{ maxValue }}</span>
              <div class="track" ref="_vpcTrack"></div>
              <div class="track-highlight" ref="trackHighlight"></div>
              <button class="track-btn track1" ref="track1" @click="setRange()"></button>
              <button class="track-btn track2" ref="track2" @click="setRange()"></button>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4">
        <button
            @click="setRange()"
            class="
            px-4
            py-2
            bg-gray-darker
            font-semibold
            text-white
            hover:bg-gray-800
          "
        >
          Browse Now
        </button>
      </div>
    </div>
    <!-- Browse by Categories -->

    <div class="border">
      <h2 class="px-4 py-3 font-semibold"
          style=" font-size: 18px; font-weight: 900; font-stretch: normal; font-style: normal; line-height: 2; letter-spacing: normal; text-align: left; color: #101010;">
        Stores
      </h2>
      <div>
        <v-row class="mx-1 my-2 flex flex-col flex-wrap justify-center">
          <router-link
              v-for="(retailer, index) in retailers"
              :key="`retailer-${index}`"
              :to="{name: 'Retailer', params: {id: retailer.id, name: retailer.name, logo: retailer.logo, phone: retailer.phone, detail: retailer.description, lat: retailer.lat, lng: retailer.lng}}">
            <v-row>
              <v-col
                  class="
                  col-md-10
              relative
              py-3
              hover:bg-gray-200
              cursor-pointer
              flex flex-row
              justify-between
            "
                  style="color: black"
              >
                <span>{{ retailer.name }}</span>
              </v-col>
              <v-col class="col-md-2">
                <v-btn icon>
                  <v-icon color="black lighten-1">mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </router-link>
        </v-row>
      </div>
      <div class="flex justify-end items-center px-4 py-4">
        <a
            href="/retailers"
            class="flex items-center space-x-2 text-gray-700 hover:text-gray-900"
        >
          <span class="font-semibold secondary--text">View all Retailers</span>
          <span class="flex items-center">
            <svg
                class="w-4 h-4 text-burning-orange-500"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
              ></path>
            </svg>
          </span>
        </a>
      </div>
    </div>
    <!-- Retailers -->

    <div class="border">
      <h2
          class="px-4 py-3 font-semibold"
          style=" font-size: 18px; font-weight: 900; font-stretch: normal; font-style: normal; line-height: 2; letter-spacing: normal; text-align: left; color: #101010;"
      >
        Product Tags
      </h2>
      <div>
        <v-card-text>
          <v-row
              active-class="black accent-4 white--text"
          >
            <v-col
                v-for="(tag, index) in tags" :key="`tag-${index}`"
                column
            >
              <v-chip
                  @click="selectChip()"
                  v-model="selection"
              >
                {{ tag }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </div>
    <!-- Tags -->

    <div
        class="
        text-center
        bg-gray-darker
        text-white
        font-semibold
        px-1
        flex
        flex-col
        justify-center
        py-8
        text-base
      "
    >
      <p>DashCoupons</p>
      <p class="md:text-3xl text-xl leading-tight my-8">
        Top Brands
        <br/>
        Huge Selection
      </p>
      <button
          class="
          uppercase
          border-2 border-white
          text-gray-300
          px-6
          py-2
          rounded
          font-semibold
          hover:border-white
          hover:text-white
        "
      >
        Buy Now
      </button>
    </div>
    <!-- Ad -->
  </div>
</template>
<style scoped>
.borderColor {
  border: 0.4px solid #ccc !important;

}
</style>

<script>
import axios from "axios";
import HelperMixin from "@/mixins/Helpers";

export default {
  mixins: [HelperMixin],
  props: ["price"],
  data() {
    return {
      categories: [
        {
          icon: "accessories-icon.png",
          name: "Accessories",
        },
        {
          icon: "electronics-icon.png",
          name: "Electronics",
        },
        {
          icon: "retail-icon.png",
          name: "Retail",
        },
        {
          icon: "speciality-icon.png",
          name: "Speciality",
        },
        {
          icon: "entertainment.png",
          name: "Entertainment",
        },
        {
          icon: "clothing.png",
          name: "Clothing",
        }
      ],
      selection: 1,
      min: 0.5,
      max: 500,
      minValue: 0.50,
      maxValue: 500,
      step: 5,
      totalSteps: 0,
      percentPerStep: 1,
      trackWidth: null,
      isDragging: false,
      pos: {
        curTrack: null
      },
      state: localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")).state : "",
      city: localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")).city : "",
      zipCode: localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")).zipcode : "",
      searchString: localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")).searchString : "",
    };
  },
  methods: {
    fetchRetailers() {
      axios({
        method: "get",
        url: `${this.$store.state.baseURL}/api/retailers`,
        data: {
          state: this.state,
          city: this.city,
          zipCode: this.zipcode,
          searchString: this.searchString,
          getFeatured: false,
          couponId: 0,
          category: ""
        }
      })
          .then((response) => {
            this.retailers = response.data.slice(0, 8);
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
            this.loading = false;
          });
    },
    goToMicrosite(logo, name, phone) {
      this.$router.push({name: 'Retailer', params: {name: name, logo: logo, phone: phone}})
    },
    setRange() {
      this.$emit('click', {
        min: this.$refs.track1.value,
        max: this.$refs.track2.value
      })
    },
    splitRange() {

    },
    zoomOut() {
      this.zoom = (this.zoom - 10) || 0
    },
    zoomIn() {
      this.zoom = (this.zoom + 10) || 100
    },
    getpath(id) {
      return `/retailers/retailer/${id}`;
    },
    moveTrack(track, ev) {

      let percentInPx = this.getPercentInPx();

      let trackX = Math.round(this.$refs._vpcTrack.getBoundingClientRect().left);
      let clientX = ev.clientX;
      let moveDiff = clientX - trackX;

      let moveInPct = moveDiff / percentInPx
      // console.log(moveInPct)

      if (moveInPct < 1 || moveInPct > 100) return;
      let value = (Math.round(moveInPct / this.percentPerStep) * this.step) + this.min;
      if (track === 'track1') {
        if (value >= (this.maxValue - this.step)) return;
        this.minValue = value;
      }

      if (track === 'track2') {
        if (value <= (this.minValue + this.step)) return;
        this.maxValue = value;
      }

      this.$refs[track].style.left = moveInPct + '%';
      this.setTrackHightlight()

    },
    mousedown(ev, track) {

      if (this.isDragging) return;
      this.isDragging = true;
      this.pos.curTrack = track;
    },

    touchstart(ev, track) {
      this.mousedown(ev, track)
    },

    mouseup() {
      if (!this.isDragging) return;
      this.isDragging = false
    },

    touchend(ev, track) {
      this.mouseup(ev, track)
    },

    mousemove(ev, track) {
      if (!this.isDragging) return;
      this.moveTrack(track, ev)
    },

    touchmove(ev, track) {
      this.mousemove(ev.changedTouches[0], track)
    },

    valueToPercent(value) {
      return ((value - this.min) / this.step) * this.percentPerStep
    },

    setTrackHightlight() {
      this.$refs.trackHighlight.style.left = this.valueToPercent(this.minValue) + '%'
      this.$refs.trackHighlight.style.width = (this.valueToPercent(this.maxValue) - this.valueToPercent(this.minValue)) + '%'
    },

    getPercentInPx() {
      let trackWidth = this.$refs._vpcTrack.offsetWidth;
      let oneStepInPx = trackWidth / this.totalSteps;
      // 1 percent in px
      return oneStepInPx / this.percentPerStep;
    },

    setClickMove(ev) {
      let track1Left = this.$refs.track1.getBoundingClientRect().left;
      let track2Left = this.$refs.track2.getBoundingClientRect().left;
      // console.log('track1Left', track1Left)
      if (ev.clientX < track1Left) {
        this.moveTrack('track1', ev)
      } else if ((ev.clientX - track1Left) < (track2Left - ev.clientX)) {
        this.moveTrack('track1', ev)
      } else {
        this.moveTrack('track2', ev)
      }
    },
    gotoCategory() {
      this.$router.push({name: 'SpecificCategoryPage'})
    }
  },
  mounted() {
    this.fetchRetailers();
    this.totalSteps = (this.max - this.min) / this.step;

    // percent the track button to be moved on each step
    this.percentPerStep = 100 / this.totalSteps;
    // console.log('percentPerStep', this.percentPerStep)

    // set track1 initial
    document.querySelector('.track1').style.left = this.valueToPercent(this.minValue) + '%'
    // track2 initial position
    document.querySelector('.track2').style.left = this.valueToPercent(this.maxValue) + '%'
    // set initial track highlight
    this.setTrackHightlight()

    var self = this;

    ['mouseup', 'mousemove'].forEach(type => {
      document.body.addEventListener(type, (ev) => {
        // ev.preventDefault();
        if (self.isDragging && self.pos.curTrack) {
          self[type](ev, self.pos.curTrack)
        }
      })
    });

    ['mousedown', 'mouseup', 'mousemove', 'touchstart', 'touchmove', 'touchend'].forEach(type => {
      document.querySelector('.track1').addEventListener(type, (ev) => {
        ev.stopPropagation();
        self[type](ev, 'track1')
      })

      document.querySelector('.track2').addEventListener(type, (ev) => {
        ev.stopPropagation();
        self[type](ev, 'track2')
      })
    })

    // on track clik
    // determine direction based on click proximity
    // determine percent to move based on track.clientX - click.clientX
    document.querySelector('.track').addEventListener('click', function (ev) {
      ev.stopPropagation();
      self.setClickMove(ev)

    })

    document.querySelector('.track-highlight').addEventListener('click', function (ev) {
      ev.stopPropagation();
      self.setClickMove(ev)

    })
  },
  computed: {
    tags() {
      return this.$store.state.tags;
    },
    retailers() {
      return this.$store.state.retailers;
    },
    // categories() {
    //   return this.$store.state.categories;
    // },
  },
};
</script>
<style scoped>
#app {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.range-value {
  position: absolute;
  top: -2rem;
}

.range-value.min {
  left: 0;
}

.range-value.max {
  right: 0;
}

.track-container {
  width: 100%;
  position: relative;
  cursor: pointer;
  height: 0.5rem;
}

.track,
.track-highlight {
  display: block;
  position: absolute;
  width: 100%;
  height: 0.5rem;
}

.track {
  background-color: #ddd;
}

.track-highlight {
  background-color: #ddd;
  z-index: 2;
}

.track-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  cursor: pointer;
  display: block;
  position: absolute;
  z-index: 2;
  width: 1rem;
  height: 1rem;
  top: calc(-50% - 0.1rem);
  margin-left: -1rem;
  border: none;
  border-radius: 100%;
  background-color: black;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
  transition: box-shadow .3s ease-out, background-color .3s ease, -webkit-transform .3s ease-out;
  transition: transform .3s ease-out, box-shadow .3s ease-out, background-color .3s ease;
  transition: transform .3s ease-out, box-shadow .3s ease-out, background-color .3s ease, -webkit-transform .3s ease-out;
}
.access-img{
  width: 40px;
  height: 40px;
  margin: 0 0 20px;
  padding: 0.8px 3.1px 5.1px 2.8px;
  border: solid 1px #e0e0e0;
  background-color: #f6f6f8;
}
</style>
