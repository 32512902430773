<template>
  <div>
    <router-link :to="{name: 'FreeCoupon', params:{id: item.id, title:item.title, expiry:item.expiry, image:item.avatar, price:item.price, retailer:item.advertiser_name, description: item.description}}">
      <div class="border flex justify-center top items-center">
        <img
            v-bind:src="item.avatar"
            :alt="item.name"
            style=" width: 100%; margin-left: auto; margin-right: auto; object-fit: contain;"
            @error="errorHandler"
        />
      </div>
      <div class="font-semibold bottom">
        <p class="mb-0 truncate text-gray-800 text-lg mt-2 ms-2">{{ item.title }}</p>
        <div class="flex flex-row justify-left">
          <div style="display: flex; flex-direction: row; justify-content: space-between">
            <p class="mb-0 text-burning-orange-500">By: </p>
            <p class="mb-0 block truncate pl-2" style="color: #b0b0b0">{{ item.advertiser_name }}</p>
          </div>
          <div class="flex flex-row text-right ml-3">
            <p class="font-semibold p-0 mb-0 truncate text-right">
              Discount: <span class="ml-1 text-burning-orange-500"
            >{{ item.discount }} Off</span>
            </p>
          </div>
        </div>
        <p
            class="flex items-center justify-center space-x-1 py-1 border-2 border-burning-orange-500 rounded"
        >
          <svg
              class="w-6 h-6 text-orange-500"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <span class="text-burning-orange-500"> Expires: </span>
          <span class="text-black">{{ formatDate(item.expiry) }}</span>
        </p>

        <button
            type="button"
            class="marignForButton btn_bg block w-full py-2 text-white font-semibold rounded"
        >
          Get Deal
        </button>
      </div>

    </router-link>
  </div>
</template>
<script>
import HelperMixin from "@/mixins/Helpers";
import moment from "moment";
// import axios from "axios";

export default {
  mixins: [HelperMixin],
  name: 'CommonItem',
  methods: {
    formatDate(date) {
      return moment(date).format('M/d/YYYY');
    },
    getpath(pathDefine, id) {
      return pathDefine ? `/microsite/${id}` : '/retaileroffer'
    },
    errorHandler(event) {
      event.target.src = `${this.baseURL}/no_image.png`
    },
    getDeal(name, expiry, image, price, by) {
      this.$router.push({
        name: 'FreeCoupon', params: {
          title: name,
          expiry: expiry,
          image: image,
          price: price,
          retailer: by,
        }
      })
    }
  },
  // mounted() {
  //   axios
  //       .get('http://18.237.231.25:5000/api/coupons')
  //       .then(response => (this.items = response.data))
  // },
  props: {
    isCoupon: Boolean,
    item: null
  }
}
</script>
<style scoped>
.marignForButton {
  margin-top: 2px;
}

.btn_bg {
  width: 267px;
  height: 40px;
  margin: 9.6px 0 0 0;
  padding: 10px 0 0 8px;
  border-radius: 4px;
  background-image: url("../assets/svg/local/deal_button.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.top {
  width: 267px;
  height: 165px;
  margin: 0 5px -10px 0;
  padding: 20.9px 68.6px 21px 70px;
  border: solid 1px #e5e5e5;
  background-color: #fff;
}

.bottom {
  width: 267px;
  height: 141px;
  margin: 0 0 12.3px 0;
  border: solid 1px #e5e5e5;
  background-color: #fff;
}
</style>
