<template>
  <div>
    <AppHeader :key="appHeaderKey"
               v-model="searchString"
               :callBack="reRenderRouterView"/>


    <div class="container mx-auto">
      <div class="flex py-6 space-x-4">
        <div class="w-1/3 sm:w-1/4">
          <Sidebar v-model="range"/>
        </div>
        <div class="flex-1 bg space-y-8">
          <div style="height: 350px" class="relative overflow-hidden">
            <img
                src="@/assets/images/shopping.jpg"
                class="object-cover w-full h-full"
                alt="Shopping"
            />
            <div
                class="absolute flex items-end p-16 bg-black bg-opacity-50 inset-0 text-white"
            >
              <div class="leading-tight">
                <p class="text-6xl uppercase font-semibold text-center">Big Sale</p>
                <p class="text-2xl font-semibold text-center">Save up to 50% off</p>
              </div>
            </div>
          </div>
          <div class="border p-4">
            <div class="flex items-baseline space-x-4">
              <h3 class="text-2xl"><strong>Paid Featured</strong> Offers</h3>
              <p class="text-gray-600 font-semibold">
                Discover the latest from your favorite index.
              </p>
            </div>
            <div class="row">
<!--              <div-->
<!--                  v-for="coupon in filteredCoupons.slice((currentPage-1)*perPage,(currentPage-1)*perPage+perPage)  || RangeFilteredCoupons || zipCodeFilteredCoupons"-->
<!--                  :key="coupon.id" class="col-md-3">-->
<!--                <div class="top">-->
<!--                  <img-->
<!--                      v-bind:src="coupon.product_image"-->
<!--                      :alt="coupon.name"-->
<!--                      style=" width: 113px; height: 123px; object-fit: contain;"-->
<!--                  />-->
<!--                </div>-->
<!--                <div class="font-semibold bottom">-->
<!--                  <h3 class="mb-0 truncate mt-2 text-left" style="color: #202020">{{ coupon.name }}</h3>-->
<!--                  <div class="flex flex-row justify-left">-->
<!--                    <p class="mb-0 text-burning-orange-500">By: </p>-->
<!--                    <p class="mb-0 block truncate pl-2" style="color: #b0b0b0">{{ coupon.advertiser_name }}</p>-->
<!--                  </div>-->
<!--                  <p-->
<!--                      class="flex items-center justify-center space-x-1 py-1 border-2 border-burning-orange-500 rounded"-->
<!--                  >-->
<!--                    <svg-->
<!--                        class="w-6 h-6 text-black"-->
<!--                        fill="none"-->
<!--                        stroke="currentColor"-->
<!--                        viewBox="0 0 24 24"-->
<!--                        xmlns="http://www.w3.org/2000/svg"-->
<!--                    >-->
<!--                      <path-->
<!--                          stroke-linecap="round"-->
<!--                          stroke-linejoin="round"-->
<!--                          stroke-width="2"-->
<!--                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"-->
<!--                      ></path>-->
<!--                    </svg>-->
<!--                    <span class="text-burning-orange-500"> Expires: </span>-->
<!--                    <span class="text-black">{{ formatDate(coupon.expire_date) }}</span>-->
<!--                  </p>-->

<!--                  <button-->
<!--                      type="button"-->
<!--                      @click="getDeal(coupon.name, coupon.expire_date, coupon.product_image, coupon.price, coupon.advertiser_name)"-->
<!--                      class="marignForButton btn_bg block w-full py-2 text-white font-semibold rounded"-->
<!--                  >-->
<!--                    Get Deal-->
<!--                  </button>-->
<!--                </div>-->
<!--              </div>-->
              <div
                  v-for="coupon in items.slice((currentPage-1)*perPage,(currentPage-1)*perPage+perPage)  || RangeFilteredCoupons || zipCodeFilteredCoupons"
                  :key="coupon.id" class="col-md-4 col-sm-4"  style="width: 252px; margin-left: 24px">
                <div>
                  <img src="../assets/svg/featured/featured-tab.svg" alt="img" style="width: 15%">
                  <img
                      v-bind:src="coupon.avatar"
                      :alt="coupon.title"
                      style=" width: 50%; height: 123px; object-fit: contain; margin-left: auto; margin-right: auto"
                  />
                </div>
                <div class="font-semibold bottom">
                  <h3 class="mb-0 truncate mt-2 text-left" style="color: #202020">{{ coupon.title }}</h3>
                  <div class="flex flex-row justify-left">
                    <div style="display: flex; flex-direction: row; justify-content: space-between">
                      <p class="mb-0 text-burning-orange-500">By: </p>
                      <router-link
                          :to="{name: 'Retailer', params: {id:coupon.retailer_id, name: coupon.advertiser_name}}"
                          class="mb-0 block truncate pl-2"
                          style="color: #b0b0b0">{{ coupon.advertiser_name }}
                      </router-link>
                    </div>
                    <div class="flex flex-row text-right ml-3">
                      <p class="font-semibold p-0 mb-0 truncate text-right">
                        Price: <span class="ml-1 text-burning-orange-500"
                      >{{ coupon.price }} Off</span>
                      </p>
                    </div>
                  </div>
                  <p
                      class="flex items-center justify-center space-x-1 py-1 border-2 border-burning-orange-500 rounded"
                  >
                    <svg
                        class="w-6 h-6 text-black"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <span class="text-burning-orange-500"> Expires: </span>
                    <span class="text-black">{{ formatDate(coupon.expiry) }}</span>
                  </p>

                  <button
                      type="button"
                      @click="getDeal(coupon.title, coupon.expiry, coupon.avatar, coupon.price, coupon.advertiser_name)"
                      class="marignForButton btn_bg block w-full py-2 text-white font-semibold rounded"
                  >
                    Buy Now
                  </button>
                </div>
              </div>
              <router-link to="/featured" style="color: black"> View Free offers</router-link>
            </div>
            <div v-if="coupons.length > 0 || items.length > 0" class="float-right">
              <b-pagination
                  v-model="currentPage"
                  class="ml-lg-5 mt-3"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Sidebar from "@/layout/Sidebar";
import axios from "axios";
import moment from "moment";
import Header from "@/layout/Header";

export default {
  data() {
    return {
      appHeaderKey: Math.random() + 1,
      coupons: [],
      searchString: "",
      range: '',
      perPage: 12,
      currentPage: 1,
      loading: true,
      state: localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")).state : "",
      city: localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")).city : "",
      zipCode: localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")).zipcode : "",
      // searchString: localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")).searchString : "",
    };
  },
  computed: {
    items(){
      return this.$store.state.items
    },
    storeCoupons() {
      return this.$store.state.coupons
    },
    filteredCoupons() {
      return this.coupons.filter(post => {
        return post.name.toLowerCase().includes(this.searchString)
      })
    },
    RangeFilteredCoupons() {
      return this.coupons.filter(coupon => {
        return (coupon.price > 0 && coupon.price < this.range);
      })
    },
    zipCodeFilteredCoupons() {
      return this.coupons.filter(coupon => {
        return coupon.zip_code.toString().includes(this.coupons.zip_code)
      })
    },
    rows() {
      return this.coupons.length || this.items.length
    }
  },
  methods: {

    fetchZipCodes(value) {
      axios({
        method: "get",
        url: `${this.$store.state.baseURL}/api/Coupons/GetZipcodes?SearchString=${value}`,
      })
          .then((response) => {
            this.loading = true;
            this.zipCodes = response.data.map(
                (d) => `${d.state}, ${d.city}, ${d.zip_code}`
            );
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
            this.loading = false;
          });
    },
    onLocationSave() {
      if (this.zipcode) {
        const loc = this.zipcode.split(", ");
        this.location.state = loc[0];
        this.location.city = loc[1];
        this.location.zipcode = loc[2];
        this.location.searchString = this.searchString;
        localStorage.setItem("location", JSON.stringify(this.location));
        this.reRenderRouterView();
        this.hideLocationModal();
      }
    },
    fetchGeoLocation() {
      navigator.geolocation.getCurrentPosition(
          (position) => {
            axios({
              method: "get",
              url: `http://api.positionstack.com/v1/reverse?access_key=df3de8a76c1377e1b3095987c4b24ddb&query=${position.coords.latitude},${position.coords.longitude}`,
              headers: {"Access-Control-Allow-Origin": "*"}
            }).then((response) => {
              console.log(response.data.data[0].locality);
              this.location.city = response.data.data[0].locality
              localStorage.setItem("location", JSON.stringify(this.location));
              this.reRenderRouterView()
              if (response.data.data[0].locality) {
                this.hideLocationModal()
              } else {
                this.showLocationModal();
              }
            });
          },
          (error) => {
            console.log(error.message);
          }
      );
    },

    reRenderRouterView() {
      this.routerViewKey++;
    },
    showLocationModal() {
      this.dialog = true;
    },
    hideLocationModal() {
      this.dialog = false;
    },
    reRenderAppheader() {
      this.appHeaderKey++;
    },
    fetchCoupons() {
      axios({
        method: "get",
        url: `${this.$store.state.baseURL}/api/coupons`,
      })
          .then((response) => {
            this.coupons = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
            this.loading = false;
          });
    },
    formatDate(date) {
      return moment(date).format('M/d/YYYY');
    },
    getDeal(name, expiry, image, price, by) {
      this.$router.push({
        name: 'PaidCoupon', params: {
          title: name,
          expiry: expiry,
          image: image,
          price: price,
          retailer: by,
        }
      })
    }
  },
  mounted() {
    this.fetchCoupons();
    this.fetchGeoLocation();
  },
  components: {
    AppHeader: Header,
    Sidebar,
  },
};
</script>
<style>
.bg{
  width: 850px;
  height: 1380px;
  margin: 0 390px 30px 25px;
  padding: 12px 23px 22px;
}
.offer-bg {
  width: 252px;
  height: 312px;
}

.btn_bg {
  width: 252px;
  height: 38px;
  margin: 8.7px 0 0;
  background-image: url("../assets/svg/local/deal_button.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.top {
  width: 251.6px;
  height: 164.9px;
  border: solid 1px #e5e5e5;
  background-color: #fff;
}

.bottom {
  width: 252px;
  height: 141px;
  margin: 0 5px 12.3px 0;
  border: solid 1px #e5e5e5;
  background-color: #fff;
}
</style>
