<template>
  <div>
    <AppHeader :key="appHeaderKey" v-bind.sync="searchString" v-model="searchString" :callBack="reRenderRouterView"/>
    <div class="py-10" style="background-color: #f6f6f8">
      <div class="container mx-auto">
        <h3 class="text-2xl text-center">
          <strong>Browse Coupons</strong> By category
        </h3>
        <p class="text-gray-600 text-center">
          Enhance your shopping experience with categories.
        </p>
        <hr class="my-10"/>
        <div class="flex flex-row flex-wrap border-box md:inline-flex">
          <div v-for="(category, index) in categories" :key="index">
            <router-link v-bind:to="'/category/' + category.name">
              <v-col class="m-2" v-if="index < (categories.length -1)">
                <v-card
                    class="flex flex-col justify-center p-4 text-center"
                    color="rgb(255, 255, 255, 0.0)"
                    elevation="0"
                >
                  <img
                      :src="getImageUrl(category.icon)"
                      :alt="category.name"
                      class="p-2 bg-white h-24 w-24"
                      style="border-radius: 100%"
                  />
                  <p class="mt-2">{{ category.name }}</p>
                </v-card>
              </v-col>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white my-10 px-10">
      <div class="m-4">
        <Ad/>
      </div>
      <div class="flex flex-row justify-between w-full p-2">
        <div class="font-bold w-1/2">All Categories</div>
        <div
            class="
            flex-auto
            w-1/3
            flex
            items-stretch
            ml-12
            relative
            hidden
            sm:inline-flex
          "
        >
          <input
              placeholder="Search categories..."
              class="
              pl-10
              pr-2
              py-2
              border-0
              rounded
              text-sm
              w-full
              max-w-sm
              placeholder-gray-700
              bg-white
            "
          />
          <div
              class="
              absolute
              inset-y-0
              left-0
              flex
              items-center
              px-2
              text-gray-600
            "
          >
            <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <button
              type="button"
              class="
              bg-burning-orange-500
              text-white
              px-2
              rounded
              ml-2
              hover:bg-burning-orange-600
            "
          >
            <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <div class="border flex flex-row flex-wrap p-4">
        <h1 v-for="(category, index) in categories" :key="index" class="md:w-1/6 w-1/3">
          <router-link v-bind:to="'category/' + category.name"
          ><p class="mt-2" style="color: black" v-if="index < 9">
            {{ category.name }}
          </p></router-link
          >
        </h1>
      </div>
    </div>
  </div>
</template>


<script>
import HelperMixin from "@/mixins/Helpers";
import Ad from '../components/Ad.vue';
import Header from "@/layout/Header";

export default {
  data() {
    return {
      appHeaderKey: Math.random() + 1,
      searchString: ''
    }
  },
  methods: {
    reRenderRouterView() {
      this.routerViewKey++;
    },
    showLocationModal() {
      this.dialog = true;
    },
    hideLocationModal() {
      this.dialog = false;
    },
    reRenderAppheader() {
      this.appHeaderKey++;
    }
  },
  components: {Ad, AppHeader: Header},
  mixins: [HelperMixin],
  computed: {
    categories() {
      return this.$store.state.categories;
    },
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }

      return 1;
    },
  },
};
</script>
