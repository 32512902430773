<template>
  <div :class="[onModal ? 'flex flex-col' : 'flex flex-col h-screen']">
    <div :class="[onModal ? 'hidden' : 'bg-gray-darker']">
      <div class="container mx-auto">
        <div class="flex justify-between items-center py-2">
          <div class="flex-shrink-0 py-2">
            <router-link to="/" class="text-white text-2xl">
              <img
                class="w-48"
                src="@/assets/images/dashshop_Logo.png"
                alt="Dash Shop"
              />
            </router-link>
          </div>
          <div class="text-gray-400 text-xs">
            <ul class="flex space-x-4">
              <li>
                <router-link to="/" class="hover:text-white">Cart</router-link>
              </li>
              <li>
                <router-link to="/" class="hover:text-white">
                  Submit a Coupon
                </router-link>
              </li>
              <li>
                <router-link to="/" class="hover:text-white">
                  How It Works
                </router-link>
              </li>
              <li>
                <router-link to="/login" class="hover:text-white">
                  Log In
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-1 bg-gray-100">
      <div class="w-full max-w-xl mx-auto py-12 space-y-6">
        <h2 class="text-2xl text-center">
          <strong>Sign In</strong> to discover discount offers!
        </h2>
        <div :class="[onModal ? 'hidden' : 'flex justify-between py-4']">
          <router-link
            to="/login"
            class="
              block
              flex-1
              uppercase
              py-4
              border-b-4
              text-center
              hover:bg-gray-200
            "
          >
            I have an account
          </router-link>
          <router-link
            to="/signup"
            class="
              block
              flex-1
              uppercase
              py-4
              border-b-4
              text-center
              hover:bg-gray-200
              border-burning-orange-500
            "
          >
            I'm a new customer
          </router-link>
        </div>
        <v-form v-model="valid">
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail*"
            required
            placeholder="fill email"
            class="form-input form-control"
          ></v-text-field>
          <v-text-field
            v-model="mobileNumber"
            label="Mobile number"
            placeholder="fill Mobile number"
          ></v-text-field>
          <v-text-field
            v-model="zipcode"
            label="Zip Code*"
            :rules="zipcodeRules"
            placeholder="fill zipcode"
            required
          ></v-text-field>
          <v-text-field
            v-model="password"
            label="Password*"
            :rules="passwordRules"
            type="password"
            required
            placeholder="fill password"
          ></v-text-field>
          <v-text-field
            v-model="confirmPassword"
            label="Confirm Password*"
            :rules="confirmPasswordRules"
            placeholder="confirm password"
            type="password"
            required
          ></v-text-field>
          <div class="mt-4">
            <label for="receiveEmails" class="flex space-x-4 items-start">
              <input
                type="checkbox"
                name="receiveEmails"
                id="receiveEmails"
                class="mt-1"
              />
              <span>
                Yes, I want to save money by receiving personalized DashCoupon
                emails with awesome discount offers.
              </span>
            </label>
          </div>
          <div class="mt-8 space-y-4">
            <p class="text-center text-xs">
              By clicking the button below, you are agreeing to the
              <a href="#" class="text-burning-orange-500 hover:underline">
                Terms of Use
              </a>
              and the
              <a href="#" class="text-burning-orange-500 hover:underline">
                Privacy Statement
              </a>
              .
            </p>
            <p></p>
            <v-btn
                depressed
                block
                text
                @click="signUp"
            >
              Sign Up
            </v-btn>
          </div>
        </v-form>
      </div>
      <div>
        <v-snackbar
          v-model="snackbar"
          top
          :content-class="this.snackbarBgColor"
        >
        <div class="flex flex-row justify-between items-center">
          {{ signUpMessage }}

          <v-btn color="pink" text @click="snackbar = false"> X </v-btn></div>
        </v-snackbar>
      </div>
    </div>
    <div :class="[onModal ? 'hidden' : 'bg-gray-darker text-white py-8']">
      <div class="container mx-auto">
        <div class="flex justify-between items-center space-x-12">
          <div class="flex">
            <div class="w-48">
              <img
                class="w-full"
                src="@/assets/images/dashshop_Logo.png"
                alt="Dash Shop"
              />
            </div>
            <div class="max-w-sm ml-12">
              <p class="text-xs">
                We have our very own fleet of delivery vans. Your order will be
                packed with care at warehouse and delivered right to your door.
              </p>
              <p class="mt-4 text-xs">
                &copy; {{ new Date().getFullYear() }} DashCoupons. All Rights
                Reserved!
              </p>
            </div>
          </div>
          <div>
            <h3>Follow Us</h3>
            <ul class="flex space-x-6 mt-2">
              <!-- Twitter -->
              <li class="flex-shrink-0">
                <a
                  class="
                    flex
                    items-center
                    justify-center
                    h-8
                    w-8
                    rounded-full
                    border border-white
                  "
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    class="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M17.3 6.2c0 0.2 0 0.3 0 0.5 0 5-3.8 10.7-10.7 10.7 -2.1 0-4.1-0.6-5.8-1.7 0.3 0 0.6 0.1 0.9 0.1 1.8 0 3.4-0.6 4.7-1.6 -1.7 0-3-1.1-3.5-2.6 0.2 0 0.5 0.1 0.7 0.1 0.3 0 0.7 0 1-0.1C2.8 11.2 1.5 9.7 1.5 7.8c0 0 0 0 0 0 0.5 0.3 1.1 0.5 1.7 0.5 -1-0.7-1.7-1.8-1.7-3.1 0-0.7 0.2-1.3 0.5-1.9C3.9 5.5 6.7 7 9.9 7.2 9.8 6.9 9.8 6.6 9.8 6.3c0-2.1 1.7-3.8 3.8-3.8 1.1 0 2.1 0.5 2.8 1.2 0.9-0.2 1.7-0.5 2.4-0.9 -0.3 0.9-0.9 1.6-1.7 2.1 0.8-0.1 1.5-0.3 2.2-0.6C18.7 5.1 18.1 5.7 17.3 6.2z"
                      fill="#FFF"
                    />
                  </svg>
                </a>
              </li>
              <!-- Instagram -->
              <li
                class="
                  flex-shrink-0 flex
                  items-center
                  justify-center
                  h-8
                  w-8
                  rounded-full
                  border border-white
                "
              >
                <a
                  class="
                    flex
                    items-center
                    justify-center
                    h-8
                    w-8
                    rounded-full
                    border border-white
                  "
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                    class="w-4 h-4"
                  >
                    <path
                      d="M17.946 5.29a6.606 6.606 0 00-.418-2.185 4.412 4.412 0 00-1.039-1.594A4.412 4.412 0 0014.895.472a6.606 6.606 0 00-2.184-.418C11.75.01 11.444 0 9 0S6.25.01 5.29.054a6.606 6.606 0 00-2.185.418A4.412 4.412 0 001.51 1.511 4.412 4.412 0 00.472 3.105a6.606 6.606 0 00-.418 2.184C.01 6.25 0 6.556 0 9s.01 2.75.054 3.71a6.606 6.606 0 00.418 2.185 4.412 4.412 0 001.039 1.594 4.411 4.411 0 001.594 1.039 6.606 6.606 0 002.184.418C6.25 17.99 6.556 18 9 18s2.75-.01 3.71-.054a6.606 6.606 0 002.185-.418 4.602 4.602 0 002.633-2.633 6.606 6.606 0 00.418-2.184C17.99 11.75 18 11.444 18 9s-.01-2.75-.054-3.71zm-1.62 7.347a4.978 4.978 0 01-.31 1.67 2.98 2.98 0 01-1.708 1.709 4.979 4.979 0 01-1.671.31c-.95.043-1.234.052-3.637.052s-2.688-.009-3.637-.052a4.979 4.979 0 01-1.67-.31 2.788 2.788 0 01-1.036-.673 2.788 2.788 0 01-.673-1.035 4.978 4.978 0 01-.31-1.671c-.043-.95-.052-1.234-.052-3.637s.009-2.688.052-3.637a4.979 4.979 0 01.31-1.67 2.788 2.788 0 01.673-1.036 2.788 2.788 0 011.035-.673 4.979 4.979 0 011.671-.31c.95-.043 1.234-.052 3.637-.052s2.688.009 3.637.052a4.979 4.979 0 011.67.31 2.788 2.788 0 011.036.673 2.788 2.788 0 01.673 1.035 4.979 4.979 0 01.31 1.671c.043.95.052 1.234.052 3.637s-.009 2.688-.052 3.637zM9 4.378A4.622 4.622 0 1013.622 9 4.622 4.622 0 009 4.378zM9 12a3 3 0 113-3 3 3 0 01-3 3zm5.884-7.804a1.08 1.08 0 11-1.08-1.08 1.08 1.08 0 011.08 1.08z"
                      fill="#fff"
                    />
                  </svg>
                </a>
              </li>
              <!-- Facebook -->
              <li
                class="
                  flex-shrink-0 flex
                  items-center
                  justify-center
                  h-8
                  w-8
                  rounded-full
                  border border-white
                "
              >
                <a
                  class="
                    flex
                    items-center
                    justify-center
                    h-8
                    w-8
                    rounded-full
                    border border-white
                  "
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                    class="w-4 h-4"
                  >
                    <path
                      d="M17 1H3C1.9 1 1 1.9 1 3v14c0 1.1 0.9 2 2 2h7v-7H8V9.5h2v-2c0-2.2 1.2-3.7 3.8-3.7l1.8 0v2.6h-1.2C13.4 6.4 13 7.1 13 7.8v1.7h2.6L15 12h-2v7h4c1.1 0 2-0.9 2-2V3C19 1.9 18.1 1 17 1z"
                      fill="#FFF"
                    />
                  </svg>
                </a>
              </li>
              <!-- LinkedIn -->
              <li
                class="
                  flex-shrink-0 flex
                  items-center
                  justify-center
                  h-8
                  w-8
                  rounded-full
                  border border-white
                "
              >
                <a
                  class="
                    flex
                    items-center
                    justify-center
                    h-8
                    w-8
                    rounded-full
                    border border-white
                  "
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                    class="w-4 h-4"
                  >
                    <path
                      d="M1 6h4v13H1V6zM3 1C1.8 1 1 2 1 3.1 1 4.1 1.8 5 3 5c1.3 0 2-0.9 2-2C5 1.9 4.2 1 3 1zM14.6 6.2c-2.1 0-3.3 1.2-3.8 2h-0.1l-0.2-1.7H6.9C6.9 7.6 7 8.9 7 10.4V19h4v-7.1c0-0.4 0-0.7 0.1-1 0.3-0.7 0.8-1.6 1.9-1.6 1.4 0 2 1.2 2 2.8V19h4v-7.4C19 7.9 17.1 6.2 14.6 6.2z"
                      fill="#FFF"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import HelpersMixin from "@/mixins/Helpers";
export default {
  mixins: [HelpersMixin],
  props: {
    onModal: Boolean,
    callBack: { type: Function },
  },
  data: function () {
    return {
      snackbar: false,
      snackbarBgColor: "bg-green-500 justify-between",
      valid: true,
      email: null,
      mobileNumber: null,
      zipcode: null,
      password: "",
      confirmPassword: "",
      loading: false,
      showRules: false,
      signUpMessage: "",
      token: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      zipcodeRules: [
        (v) => !!v || "Zipcode is required",
        (v) =>
          (v && v.length <= 5) ||
          "Zipcode must be less than or equal to 5 characters",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
      confirmPasswordRules: [
        (v) => !!v || "You need to confirm password",
        (v) => v === this.password || "Password doesnt match",
      ],
    };
  },
  methods: {
    // signUP() {
    //   if (this.valid) {
    //     this.loading = true;
    //     axios({
    //       method: "post",
    //       url: 'http://localhost:5000/api/auth/signin',
    //       data: {
    //         email: this.email,
    //         zip_code: this.zipcode,
    //         phone_number: this.mobileNumber,
    //         password: this.password,
    //       },
    //     })
    //       .then((response) => {
    //         this.loading = false;
    //         this.snackbar = true;
    //         this.signUpMessage = "Successfully registered";
    //         localStorage.setItem("token", response.data.token);
    //         localStorage.setItem("email", response.data.email);
    //         localStorage.setItem("userId", response.data.id);
    //         localStorage.setItem("zipCode", response.data.zip_code);
    //         this.onModal ? this.callBack() : this.$router.push("/");
    //       })
    //       .catch((error) => {
    //         this.errored = true;
    //         this.loading = false;
    //         this.snackbar = true;
    //         this.signUpMessage = `Error: ${error.response.data}`;
    //         this.snackbarBgColor = "bg-red-500";
    //       });
    //   } else {
    //     this.showRules = true;
    //   }
    // },
    // signUp() {
    //   axios.post('http://localhost:5000/api/auth/signup', {
    //     email: this.email,
    //     phone: this.phone,
    //     zip_code: this.zip_code,
    //     group_id: 1,
    //     password: this.password
    //   })
    //   .then()
    // },
    async signUp () {
      try {
        const { jwt, user } = await axios.post('http://localhost:5000/api/auth/signup', {
          email: this.email,
          phone_number: this.phone_number,
          zip_code: this.zip_code,
          group_id: 1,
          password: this.password
        })
        this.setUser({
          jwt,
          id: user.id,
          email: user.email
        })
        this.$router.push({
          path: '/'
        })
      } catch (err) {
        this.err = err.response?.data?.error
      }
    },
  },
};
</script>
