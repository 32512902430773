<template>
  <div>
    <AppHeader :key="appHeaderKey"
               v-model="searchString"
               :callBack="reRenderRouterView"/>
    <div class="container mx-auto">
      <div class="flex py-6 space-x-4">
        <div class="w-1/3 sm:w-1/4">
          <Sidebar />
        </div>
            <div class="flex-1 space-y-8">
              <div class="border p-4">
                <div class="flex items-baseline space-x-4">
                  <h3 class="text-2xl">
                    <strong>{{ categoryName }}</strong> Offers
                  </h3>
                  <p class="text-gray-600 font-semibold">
                    Discover the latest from your favorite index.
                  </p>
                </div>
            <div class="flex justify-center p-4" v-if="loading">
              <v-progress-circular
                  indeterminate
                  color="accent"
              ></v-progress-circular>
            </div>
            <div
                v-else-if="coupons.length > 0"
            >
              <div class="row mt-4">
                 <div v-for="item in filteredCoupons.slice((currentPage-1)*perPage,(currentPage-1)*perPage+perPage) || zipCodeFilteredCoupons || RangeFilteredCoupons" :key="item.id" class="col-md-3" style="width: 236px">
                  <div class="top">
                    <img
                        v-bind:src="item.product_image"
                        :alt="item.name"
                        style=" width: 113px; height: 123px; object-fit: contain;"
                    />
                  </div> 
                  <div class="font-semibold bottom">
                    <h3 class="mb-0 truncate mt-2 text-left" style="color: #202020">{{ item.name }}</h3>
                    <div class="flex flex-row justify-left">
                      <p class="mb-0 text-burning-orange-500">By: </p>
                      <p class="mb-0 block truncate pl-2" style="color: #b0b0b0">{{ item.advertiser_name }}</p>
                    </div>
                    <p
                        class="flex items-center justify-center space-x-1 py-1 border-2 border-burning-orange-500 rounded"
                    >
                      <svg
                          class="w-6 h-6 text-black"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                      <span class="text-burning-orange-500"> Expires: </span>
                      <span class="text-black">{{ formatDate(item.expire_date) }}</span>
                    </p>

                    <button
                        type="button"
                        @click="getDeal(item.name, item.expire_date, item.product_image, item.price, item.advertiser_name, item.description)"
                        class="marignForButton btn_bg block w-full py-2 text-white font-semibold rounded"
                    >
                      Get Deal
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="coupons.length === 0" class="row">
              <div
                  v-for="coupon in items.slice((currentPage-1)*perPage,(currentPage-1)*perPage+perPage)  || RangeFilteredCoupons || zipCodeFilteredCoupons"
                  :key="coupon.id" class="col-md-4 col-sm-6"  style="width: 252px; margin-left: 24px">
                <div class="top">
                  <img
                      v-bind:src="coupon.avatar"
                      :alt="coupon.title"
                      style=" width: 113px; height: 123px; object-fit: contain;"
                  />
                </div>
                <div class="font-semibold bottom">
                  <h3 class="mb-0 truncate mt-2 text-left" style="color: #202020">{{ coupon.title }}</h3>
                  <div class="flex flex-row justify-left">
                    <p class="mb-0 text-burning-orange-500">By: </p>
                    <p class="mb-0 block truncate pl-2" style="color: #b0b0b0">{{ coupon.advertiser_name }}</p>
                  </div>
                  <p
                      class="flex items-center justify-center space-x-1 py-1 border-2 border-burning-orange-500 rounded"
                  >
                    <svg
                        class="w-6 h-6 text-black"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <span class="text-burning-orange-500"> Expires: </span>
                    <span class="text-black">{{ formatDate(coupon.expiry) }}</span>
                  </p>

                  <button
                      type="button"
                      @click="getDeal(coupon.title, coupon.expiry, coupon.avatar, coupon.price, coupon.advertiser_name)"
                      class="marignForButton btn_bg block w-full py-2 text-white font-semibold rounded"
                  >
                    Get Deal
                  </button>
                </div>
              </div>
            </div>
            <div
                v-else
                class="w-full p-2 flex justify-center text-gray-500"
            >
              <h6>No data found</h6>
            </div>
            <div v-if="coupons.length > 0 || items.length > 0" class="float-right mt-2">
              <b-pagination
                v-model="currentPage"
                class="ml-lg-5 mt-3"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
            />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Sidebar from "@/layout/Sidebar";
import axios from "axios";
import Header from "@/layout/Header";
import moment from "moment";

export default {
  data() {
    return {
      appHeaderKey: Math.random() + 1,
      coupons: [],
      loading: true,
      searchString: "",
      range: '',
      perPage: 15,
      currentPage: 1,
      categoryName: "",
      state: localStorage.getItem("location")
        ? JSON.parse(localStorage.getItem("location")).state
        : "",
      city: localStorage.getItem("location")
        ? JSON.parse(localStorage.getItem("location")).city
        : "",
      zipCode: localStorage.getItem("location")
        ? JSON.parse(localStorage.getItem("location")).zipcode
        : ""
    };
  },
  watch: {
    $route() {
      this.setCategoryName();
      this.fetchCoupons();
    },
  },
  methods: {

    fetchCoupons() {
      axios({
        method: "get",
        // url: `${this.$store.state.baseURL}/api/coupons`
        url: 'http://18.237.231.26:5000/api/coupons',
        timeout: 500
      })
        .then((response) => {
          this.coupons = response.data.slice(0,12);
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
          this.loading = false;
        });
    },
    setCategoryName() {
      this.categoryName = this.$route.params.categoryname;
    },
    getDeal(title,price, avatar, description, expiry, discount, advertiser_name) {
      this.$router.push({name: 'InStore', params: {
          title: title,
          price: price,
          image: avatar,
          description: description,
          expiry: expiry,
          discount: discount,
          retailer: advertiser_name
        }})
    },
    formatDate(date) {
      return moment(date).format('M/d/YYYY')
    },
    reRenderRouterView() {
      this.routerViewKey++;
    },
    showLocationModal() {
      this.dialog = true;
    },
    hideLocationModal() {
      this.dialog = false;
    },
    reRenderAppheader() {
      this.appHeaderKey++;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.setCategoryName();
    this.fetchCoupons();
    console.log(this.$route.params.categoryname);
  },
  computed:{
    filteredCoupons() {
      return this.coupons.filter(post => {
        return post.name.toLowerCase().includes(this.searchString)
      })
    },
    RangeFilteredCoupons() {
      return this.coupons.filter(coupon => {
        return (coupon.price > 0 && coupon.price < this.range);
      })
    },
    zipCodeFilteredCoupons() {
      return this.coupons.filter(coupon => {
        return coupon.zip_code.toString().includes(this.coupons.zip_code)
      })
    },
    rows() {
      return this.coupons.length || this.items.length
    },
    items(){
      return this.$store.state.items
    }
  },
  components: {
    Sidebar,
    AppHeader: Header,
  },
};
</script>
<style scoped>
.bg{
  width: 850px;
  height: 1380px;
  overflow-scrolling: auto;
  margin: 0 390px 30px 25px;
  padding: 12px 23px 22px;
}
.btn_bg {
  background-image: url("../assets/svg/local/deal_button.svg");
  background-size: contain;
  background-repeat: no-repeat;
}
.top {
  width: 235.6px;
  height: 164.9px;
  margin: 0 5px -10px 0;
  padding: 20.9px 68.6px 21px 70px;
  border: solid 1px #e5e5e5;
  background-color: #fff;
}

.bottom {
  width: 235.6px;
  height: 141px;
  margin: 0 5px 12.3px 0;
  border: solid 1px #e5e5e5;
  background-color: #fff;
}
</style>
