<template>
  <div>
    <AppHeader :key="appHeaderKey" :callBack="reRenderRouterView" />
    <div class="container mx-auto">
      <div>
        <div class="row" style="display: flex; flex-direction: column; margin-left: 10%">
          <div>
            <h1 class="font-bold" style="font-size: 3.25em">Checkout (1 item)</h1>
          </div>
          <div>
            <p class="text-gray-700 mb-5">
              Please provide the following information to complete your purchase
            </p>
          </div>
        </div>
        <div class="row" style="margin-left: 10%">
          <div class="col-md-5" style="background-color: lightyellow">
            <h2 class="font-black" style="font-size: 2.5em; margin-top: 10%">Contact Information</h2>
            <div>
              <v-card>
                <v-card-text>
                  <div class="mt-4" style="display: flex; flex-direction: row; justify-content: space-between">
                    <h2 style="font-weight: bolder; color: black; font-size: larger">Contact</h2>
                    <span>
                      <h3 class="text-right">
                       usercontact@gmail.com
                      </h3>
                    </span>
                  </div>
                  <div style="display: flex; flex-direction: row; justify-content: space-between">
                    <h2 style="font-weight: bolder; color: black; font-size: larger">
                      Ship To
                    </h2>
                    <h2 style="float: right">123, address way,<br/>off address blvd, <br/> Texas US</h2>
                  </div>
                </v-card-text>
              </v-card>
            </div>
            <div>
              <h2 class="font-black" style="font-size: 2.5em; margin-top: 10%">Payment Information</h2>
              <form>
                <div class="w-full py-1">
                  <label class="font-black" for="number">Card Number</label>
                  <input
                      id="number"
                      type="text"
                      placeholder="Card Number"
                      class="w-full p-1 border-2 focus:outline-none focus:ring focus:border-blue-300 border-gray-200"
                  />
                </div>
                <div class="w-full mt-2 flex-row py-1">
                  <label class="font-black">Name on Card</label>
                  <input
                      type="text"
                      placeholder="Name on Card"
                      class="w-full border-2 focus:outline-none focus:ring focus:border-blue-300 border-gray-200 p-1"
                  />
                </div>

                <div class="w-full mt-2 py-1 flex flex-row justify-between">
                  <div>
                    <label class="font-black" for="date">Expiry date</label>
                    <input
                        id="date"
                        type="text"
                        placeholder="Expiration Date"
                        class="w-full p-1 border-2 focus:outline-none focus:ring focus:border-blue-300 border-gray-200"
                    />
                  </div>
                  <div>
                    <label class="font-black" for="security">Security Code</label>
                    <input
                        id="security"
                        type="text"
                        placeholder="Security Code"
                        class="w-full p-1 ml-2 border-2 focus:outline-none focus:ring focus:border-blue-300 border-gray-200"
                    />
                  </div>
                </div>
                <div>
                  <label class="font-black" for="zip">Zip Code</label>
                  <input
                      id="zip"
                      type="text"
                      placeholder="Zip Code"
                      class="w-full p-1 ml-2 border-2 focus:outline-none focus:ring focus:border-blue-300 border-gray-200"
                  />
                </div>
                <div class="mt-4 p-4 border-2 border-gray-200">
                  <h1 class="font-black text-black" style="font-size: 1.23rem">Billing Address</h1>
                  <p>
                    Select the address that matches your card or payment method
                  </p>
                  <hr>
                  <div class="py-2">
                    <input type="radio" id="same" name="shippingAddress" value=""/>
                    <label for="same" class="pl-4">Same as shipping address</label><br/>
                    <input type="radio" id="different" name="shippingAddress" value=""/>
                    <label for="different" class="pl-4">Use a different billing address</label>
                  </div>
                </div>
              </form>
            </div>
            <div class="mt-5 mb-5">
              <router-link to="/microsite">
                <v-btn>
                 <span class="flex items-center">
                      <svg
                          style="color: blue"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16" height="16" fill="currentColor"
                          class="bi bi-chevron-left"
                          viewBox="0 0 16 16">
                        <path fill-rule="evenodd"
                              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                              fill="blue"></path>
                      </svg>
                 </span>
                  Return to Microsite
                </v-btn>
              </router-link>
              <v-btn color="warning" style="float: right; width: 200px">
                Pay Now
              </v-btn>
            </div>
          </div>
          <div class="col-md-4 ml-3">
            <template>
              <v-card
                  class="mx-auto"
                  max-width="344"
                  outlined
              >
                <v-list-item three-line>
                  <v-list-item-avatar
                      tile
                      size="80"
                      color="grey"
                  >
                    <v-img
                        class="mt-2"
                        height="200"
                        width="100%"
                        src="https://images.unsplash.com/photo-1541280910158-c4e14f9c94a3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80"
                    ></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <div class="text-black mb-2">
                      Product Description
                    </div>
                    <v-list-item-subtitle>
                      <p>
                        Lenscrafters get 1 Free Lens craters get 1 <br/>Free Lens crafters get 1 Free
                      </p>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <div>
                    <div style="display: flex; flex-direction: row; justify-content: space-between">
                      <h2 class="font-black text-black">Subtotal</h2>
                      <h2 style="float: right">$24.55</h2>
                    </div>
                    <div style="display: flex; flex-direction: row; justify-content: space-between">
                      <h2 class="font-black text-black">Shipping: </h2>
                      <h2 style="float: right">Calculated at next step</h2>
                    </div>
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <div>
                    <div class="mt-4" style="display: flex; flex-direction: row; justify-content: space-between">
                      <h2 class="font-black text-black">Order Total: </h2>
                      <span>
                      <h3 class="text-right">
                        $24.55
                        <br/>
                         <span style="color: forestgreen; font-weight: bolder">You save $24.55</span>
                      </h3>
                    </span>
                    </div>
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  By clicking below, I agree to the <span class="text-blue-500">Terms of use</span>
                  and <span class="text-blue-500">Refund policy</span> and that i have read the
                  <span class="text-blue-500">Privacy Statement.</span>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <router-link to="/order/checkout-2">
                  <v-card-actions>

                    <v-btn
                        depressed
                        color="warning"
                        block>
                      Pay Now
                    </v-btn>
                  </v-card-actions>
                </router-link>
              </v-card>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/layout/Header";

export default {
  name: "Payment",
  data () {
    return{
      appHeaderKey: Math.random() + 1,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      delivery: {
        coupon: '148372',
        tag: require('../../assets/svg/exrract/tagicon.svg'),
        banner: require('../../assets/svg/ad.png'),
        logo: require('../../assets/svg/lenscrafters.svg'),
        description: 'LensCrafters Vision sale. 40% off Lenses with a purchase of eyeglasses',
        expiry: '10/11/19'
      },
      product:{
        image: require('../../assets/svg/img-ft-offer-1.svg'),
        description: 'LensCrafter Vision Day sale. 40% off Lens with a purchase of glasses',
        discount: '50% OFF',
        expires: '10/9/20',
        by: 'LensCrafter',
        other: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua'
      }
    }
  },
  components:{
    AppHeader: Header
  },

  methods: {
    reRenderRouterView() {
      this.routerViewKey++;
    },
    showLocationModal() {
      this.dialog = true;
    },
    hideLocationModal() {
      this.dialog = false;
    },
    reRenderAppheader() {
      this.appHeaderKey++;
    },
  }
}
</script>

<style scoped>
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>
