<template>
    <div class="flex flex-row justify-center items-center">
         <div v-bind:class="[onHeader ? 'hidden' : 'flex flex-row justify-center items-center border-box hidden']">
            <div v-for="(category, index) in categories" :key="index">
              <router-link v-bind:to="'/category/' + category.name">
                <v-col class="" v-if="index < 5">
                  <v-card
                    class="cat-box flex flex-col justify-center p-2 text-center"
                    color="rgb(255, 255, 255, 0.0)"
                    elevation="0"
                  >
                    <img
                      :src="getImageUrl(category.icon)"
                      :alt="category.name"
                      class="p-2 bg-white h-24 w-24 w-100"
                      style="border-radius: 100%"
                    />
                    <p class="mt-2">{{ category.name }}</p>
                  </v-card>
                </v-col>
              </router-link>
              <router-link to="/category">
                <v-col class="" v-if="index === (categories.length -1)">
                  <v-card
                    class="cat-box flex flex-col justify-center p-2 text-center"
                    color="rgb(255, 255, 255, 0.0)"
                    elevation="0"
                  >
                    <img
                      :src="getImageUrl(category.icon)"
                      :alt="category.name"
                      class="p-2 bg-white h-24 w-24 w-100"
                      style="border-radius: 100%;"
                    />
                    <p class="mt-2">{{ category.name }}</p>
                  </v-card>
                </v-col>
              </router-link>
            </div>
          </div>
          <div v-bind:class="[onHeader ? 'flex flex-wrap justify-center inline-flex' : 'flex flex-wrap justify-center inline-flex']">
            <v-slide-group multiple>
              <v-slide-item
                v-for="(category, index) in categories"
                :key="index"
              >
                <v-row class="flex flex-row items-center m-0 mt-0">
                   <router-link v-bind:to="'/category/' + category.name">
                  <v-col class="" v-if="index !== (categories.length -1)">
                    <v-card
                      v-bind:class="[onHeader ? 'flex flex-col items-center text-center' : 'flex flex-col justify-center justify-center text-center p-2']"
                      color="rgb(255, 255, 255, 0.0)"
                      elevation="0"
                    >
                      <img
                        :src="getImageUrl(category.icon)"
                        :alt="category.name"
                        v-bind:class="[onHeader ? 'p-2 h-12 w-12 bg-white' : 'p-2 h-24 w-24 w-100 bg-white']"
                      />
                      <p class="mt-2">{{ category.name }}</p>
                    </v-card>
                  </v-col>
                   </router-link>
                   <router-link to="/category">
                  <v-col class="" v-if="index === (categories.length -1)">
                    <v-card
                      v-bind:class="[onHeader ? 'flex flex-col items-center text-center' : 'flex flex-col justify-center justify-center text-center p-2']"
                      color="rgb(255, 255, 255, 0.0)"
                      elevation="0"
                    >
                      <img
                        :src="getImageUrl(category.icon)"
                        :alt="category.name"
                        v-bind:class="[onHeader ? 'p-2 h-12 w-12 bg-white' : 'p-2 h-24 w-24 w-100 bg-white']"
                        style="border-radius: 100%"
                      />
                      <p class="mt-2">{{ category.name }}</p>
                    </v-card>
                  </v-col>
                   </router-link>
                </v-row>
              </v-slide-item>
            </v-slide-group>
          </div>
    </div>
</template>
<script>
import HelperMixin from "@/mixins/Helpers";
export default {
  mixins: [HelperMixin],
  props: {
    onHeader: Boolean,
  },
  computed: {
    categories() {
      return this.$store.state.categories;
    },
    }
}
</script>
<style>
.cat-box{
  width: 170px;
  height: 120px;
  margin: 0 23.8px 0 23.8px;
  padding: 24px 43.6px 21px 44.4px;
  border: solid 1px #e0e0e0;
  background-color: #fff;
}
</style>
