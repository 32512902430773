<template>
  <div class="container mx-auto">
    <div class="flex py-6 space-x-6">
      <div class="w-1/3 sm:w-1/4">
        <RetailerSidebar :retailer="retailer" />
      </div>
      <div class="flex-1 space-y-8">
        <div class="p-4 border" style="min-height: 610px; height: 610px">
          <div class="flex items-baseline space-x-4">
            <h3 class="text-2xl"><strong>Retailer</strong> Offers</h3>
            <p class="text-gray-600 font-semibold">
              Discover the latest from your favorite index.
            </p>
          </div>
          <div>
            <v-container v-for="(item1, index) in renderKey" :key="index">
              <v-row no-gutters class="mt-4">
                <v-col
                  v-for="(item, i) in coupons.slice(
                    index * 12,
                    (index + 1) * 12
                  )"
                  :key="item.id"
                  sm="12"
                  md="4"
                  lg="3"
                >
                  <div class="p-2">
                    <CommonItem
                      v-bind:item="coupons[index * 12 + i]"
                      v-bind:isCoupon="true"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <div
            v-if="coupons.length === 0 && !loading"
            class="w-full p-2 flex justify-center text-gray-500"
          >
            <h6>No data found</h6>
          </div>
        </div>
        <Pagination />
      </div>
    </div>
  </div>
</template>

<script>
import RetailerSidebar from "@/layout/RetailerSidebar";
import Pagination from "../components/Pagination.vue";
import CommonItem from "@/components/CommonItem.vue";
import axios from "axios";
import moment from "moment";

export default {
  components: {
    RetailerSidebar,
    Pagination,
    CommonItem,
  },
  data: function () {
    return {
      showBanner: true,
      A: "",
      retailer: {},
      coupons: [],
      renderKey: [1]
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.fetchRetailer();
  },
  methods: {
    getpath(pathDefine, id) {
      return pathDefine ? `/microsite/${id}` : "/retaileroffer";
    },
    formatDate(date) {
      return moment(date).format("M/d/YYYY");
    },
    errorHandler(event) {
      event.target.src = `${this.$store.state.baseURL}/no_image.png`;
    },
    fetchRetailer() {
      axios({
        method: "get",
        url: `${this.$store.state.baseURL}/api/Coupons/GetRetailerDetail?id=${this.$route.params.id}`,
      })
        .then((response) => {
          this.retailer = response.data;
          this.coupons = response.data.coupons;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        });
    },
  },
};
</script>
