<template>
  <div>
    <AppHeader :key="appHeaderKey" v-bind.sync="searchString" v-model="searchString" :callBack="reRenderRouterView"/>

    <div class="container mx-auto">
      <div class="flex py-6 space-x-4">
        <div class="w-1/3 sm:w-1/4">
          <Sidebar v-model="range"/>
        </div>
        <div class="flex-1 bg space-y-8">
          <div class="border p-4">
            <div class="flex items-baseline space-x-4">
              <h3 class="text-2xl"><strong>National</strong> Offers</h3>
              <p class="text-gray-600 font-semibold">
                Discover the latest from your favorite Brands.
              </p>
            </div>
            <div class="flex justify-center p-4" v-if="loading">
              <v-progress-circular
                  indeterminate
                  color="accent"
              ></v-progress-circular>
            </div>
            <div
                v-if="coupons.length > 0"
            >
              <div class="row mt-4">
                <div v-for="coupon in filteredCoupons.slice((currentPage-1)*perPage,(currentPage-1)*perPage+perPage) || zipCodeFilteredCoupons || RangeFilteredCoupons" :key="coupon.id" class="col-md-4" style="width: 252px">
                  <div class="top">
                    <img
                        v-bind:src="coupon.product_image"
                        :alt="coupon.name"
                        style=" width: 113px; height: 123px; object-fit: contain;"
                    />
                  </div>
                  <div class="font-semibold bottom">
                    <h3 class="mb-0 truncate mt-2 text-left" style="color: #202020">{{ coupon.name }}</h3>
                    <div class="flex flex-row justify-left">
                      <div style="display: flex; flex-direction: row; justify-content: space-between;  width: 90px">
                        <p class="mb-0 text-burning-orange-500">By: </p>
                        <p class="mb-0 block truncate pl-2" style="color: #b0b0b0">{{ coupon.advertiser_name }}</p>
                      </div>
                      <div class="flex flex-row text-right ml-3">
                        <p class="font-semibold p-0 mb-0 truncate text-right">
                          Discount: <span class="ml-1 text-burning-orange-500"
                        >{{ coupon.discount }} Off</span>
                        </p>
                      </div>
                    </div>
                    <p
                        class="flex items-center justify-center space-x-1 py-1 border-2 border-burning-orange-500 rounded"
                    >
                      <svg
                          class="w-6 h-6 text-black"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                      <span class="text-burning-orange-500"> Expires: </span>
                      <span class="text-black">{{ formatDate(coupon.expire_date) }}</span>
                    </p>

                    <button
                        type="button"
                        @click="getDeal(coupon.name, coupon.expire_date, coupon.product_image, coupon.price, coupon.advertiser_name, coupon.description)"
                        class="marignForButton btn_bg block w-full py-2 text-white font-semibold rounded"
                    >
                      Get Deal
                    </button>
                  </div>
                </div>
                <router-link to="/national/paid" style="color: black"> View Paid offers</router-link>
              </div>
            </div>
            <div v-else-if="coupons.length === 0" class="row">
              <div
                  v-for="coupon in filteredItems.slice((currentPage-1)*perPage,(currentPage-1)*perPage+perPage)  || RangeFilteredCoupons || zipCodeFilteredCoupons"
                  :key="coupon.id" class="col-md-4 col-sm-6" style="width: 252px; margin-left: 24px">
                <div class="top">
                  <img
                      v-bind:src="coupon.avatar"
                      :alt="coupon.title"
                      style=" width: 113px; height: 123px; object-fit: contain;"
                  />
                </div>
                <div class="font-semibold bottom">
                  <h3 class="mb-0 truncate mt-2 text-left" style="color: #202020">{{ coupon.title }}</h3>
                  <div class="flex flex-row justify-left">
                    <div style="display: flex; flex-direction: row; justify-content: space-between; width: 90px">
                      <p class="mb-0 text-burning-orange-500">By: </p>
                      <router-link
                          :to="{name: 'Retailer', params: {id:coupon.retailer_id, name: coupon.advertiser_name}}"
                          class="mb-0 block truncate pl-2"
                          style="color: #b0b0b0">
                        {{ coupon.advertiser_name }}
                      </router-link>
                    </div>
                    <div class="flex flex-row text-right ml-3">
                      <p class="font-semibold p-0 mb-0 truncate text-right">
                        Discount: <span class="ml-1 text-burning-orange-500"
                      >{{ coupon.discount }} Off</span>
                      </p>
                    </div>
                  </div>
                  <p
                      class="flex items-center justify-center space-x-1 py-1 border-2 border-burning-orange-500 rounded"
                  >
                    <svg
                        class="w-6 h-6 text-black"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                      ></path>
                    </svg>
                    <span class="text-burning-orange-500"> Expires: </span>
                    <span class="text-black">{{ formatDate(coupon.expiry) }}</span>
                  </p>

                  <router-link
                      tag="button"
                      :to="{name: 'FreeCoupon', params: {id: coupon.id, title: coupon.name, image: coupon.avatar,  price: coupon.price, retailer: coupon.advertiser_name,}}"
                      class="marignForButton btn_bg block w-full py-2 text-white font-semibold rounded"
                  >
                    Get Deal
                  </router-link>
                </div>
              </div>
              <router-link to="/national/paid" style="color: black"> View Paid offers</router-link>
            </div>
            <div
                v-else
                class="w-full p-2 flex justify-center text-gray-500"
            >
              <h6>No data found</h6>
            </div>
            <div v-if="items.length > 0" class="float-right">
              <b-pagination
                  v-model="currentPage"
                  class="ml-lg-5 mt-3"
                  :total-rows="rows"
                  :per-page="perPage"
                  aria-controls="my-table"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Sidebar from "@/layout/Sidebar";
import axios from "axios";
import moment from "moment";
import Header from "@/layout/Header";

export default {
  data() {
    return {
      appHeaderKey: Math.random() + 1,
      coupons: [],
      searchString: "",
      range: '',
      perPage: 12,
      currentPage: 1,
      loading: true,
      state: localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")).state : "",
      city: localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")).city : "",
      zipCode: localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")).zipcode : "",
      // searchString: localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")).searchString : "",
    };
  },
  computed: {
    filteredCoupons() {
      return this.coupons.filter(post => {
        return post.name.toLowerCase().includes(this.searchString)
      })
    },
    RangeFilteredCoupons() {
      return this.coupons.filter(coupon => {
        return (coupon.price > 0 && coupon.price < this.range);
      })
    },
    zipCodeFilteredCoupons() {
      return this.coupons.filter(coupon => {
        return coupon.zip_code.toString().includes(this.coupon.zip_code)
      })
    },
    rows () {
      return this.coupons.length || this.$store.state.items.length
    },
    items(){
      return this.$store.state.items
    },
    filteredItems() {
      return this.items.filter(post => {
        return post.title.toLowerCase().includes(this.searchString)
      })
    },
  },
  methods: {

    fetchZipCodes(value) {
      axios({
        method: "get",
        url: `${this.$store.state.baseURL}/api/Coupons/GetZipcodes?SearchString=${value}`,
      })
          .then((response) => {
            this.loading = true;
            this.zipCodes = response.data.map(
                (d) => `${d.state}, ${d.city}, ${d.zip_code}`
            );
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
            this.loading = false;
          });
    },
    onLocationSave() {
      if (this.zipcode) {
        const loc = this.zipcode.split(", ");
        this.location.state = loc[0];
        this.location.city = loc[1];
        this.location.zipcode = loc[2];
        this.location.searchString = this.searchString;
        localStorage.setItem("location", JSON.stringify(this.location));
        this.reRenderRouterView();
        this.hideLocationModal();
      }
    },
    fetchGeoLocation() {
      navigator.geolocation.getCurrentPosition(
          (position) => {
            axios({
              method: "get",
              url: `http://api.positionstack.com/v1/reverse?access_key=df3de8a76c1377e1b3095987c4b24ddb&query=${position.coords.latitude},${position.coords.longitude}`,
              headers: {"Access-Control-Allow-Origin": "*"}
            }).then((response) => {
              console.log(response.data.data[0].locality);
              this.location.city = response.data.data[0].locality
              localStorage.setItem("location", JSON.stringify(this.location));
              this.reRenderRouterView()
              if (response.data.data[0].locality) {
                this.hideLocationModal()
              } else {
                this.showLocationModal();
              }
            });
          },
          (error) => {
            console.log(error.message);
          }
      );
    },

    reRenderRouterView() {
      this.routerViewKey++;
    },
    showLocationModal() {
      this.dialog = true;
    },
    hideLocationModal() {
      this.dialog = false;
    },
    reRenderAppheader() {
      this.appHeaderKey++;
    },
    fetchCoupons() {
      axios({
        method: "get",
        url: `${this.$store.state.baseURL}/api/coupons`,
        timeout: 900
      })
          .then((response) => {
            this.coupons = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
            this.loading = false;
          });
    },
    formatDate(date) {
      return moment(date).format('M/d/YYYY');
    },
    getDeal(name, description, expire_date, product_image, price, advertiser_name) {
      this.$router.push({
        name: 'FreeCoupon', params: {
          title: name,
          description: description,
          expiry: expire_date,
          image: product_image,
          price: price,
          retailer: advertiser_name,
        }
      })
    }
  },
  mounted() {
    this.fetchCoupons();
    this.fetchGeoLocation();
  },
  components: {
    AppHeader: Header,
    Sidebar,
  },
};
</script>
<style>
.bg{
  width: 850px;
  height: 1380px;
  margin: 0 390px 30px 25px;
  padding: 12px 23px 22px;
}
.offer-bg {
  width: 252px;
  height: 312px;
}

.btn_bg {
  width: 252px;
  height: 38px;
  margin: 8.7px 0 0;
  background-image: url("../assets/svg/local/deal_button.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.top {
  width: 251.6px;
  height: 164.9px;
  margin: 0 0.4px 12.3px 0;
  padding: 20.9px 68.6px 21px 70px;
  border: solid 1px #e5e5e5;
  background-color: #fff;
}

.bottom {
  width: 252px;
  height: 141px;
  margin: 0 5px 12.3px 0;
  border: solid 1px #e5e5e5;
  background-color: #fff;
}
</style>
