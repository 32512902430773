<template>
  <div>
    <AppHeader :key="appHeaderKey" :callBack="reRenderRouterView" />
    <section class="container">
      <div class="row mb-64" style="margin-bottom: 30%">
        <div class="col-md-4 col-sm-6 mt-10 ml-14">
          <div class="space-y-8">
            <div class="border"
                 style="width: 267px; height: 610px; max-height: 610px">
              <div class="logo-bg">
                <img :src="$route.params.logo" :alt="name" class="logo"/>
              </div>
              <div class="p-4 bg-gray-dark-light text-white">
                <h2 class="retailer-title">{{ $route.params.name }}</h2>
                <p>
                  <span class="retailer-phone">Phone:</span> {{ $route.params.phone }}
                </p>
                <hr class="my-4 border-gray-700"/>
                <p class="about-text">About {{ name }}</p>
                <p class="full-desc">
                  {{ $route.params.detail }}
                </p>
              </div>
              <div class="px-4 py-2 ">
                <p class="website">
                  Website:
                  <span class="text-black">lenscrafters.com</span>
                </p>
              </div>
              <div
              >
                <GmapMap
                    :center="center"
                    :zoom="20"
                    map-type-id="terrain"
                    style="width: 264px; height: 259px"
                >
                  <GmapMarker
                      :key="index"
                      v-for="(m, index) in markers"
                      :position="m.position"
                      :clickable="true"
                      @click="center=m.position"
                  />
                </GmapMap>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7 col-sm-6 mt-10" style="margin-left: -5%">
          <div class="flex flex-row">
            <h1 class="page-title">
              <strong style="font-weight: bolder">Retailer's</strong> Offers
            </h1>
            <h3 class="small-title">
              Discover the latest form your favorite index
            </h3>
          </div>
          <div class="flex justify-center p-4" v-if="loading">
            <v-progress-circular
                indeterminate
                color="accent"
            ></v-progress-circular>
          </div>
          <div class="row" style="width: 950px;" v-else-if="coupons.length > 0">
            <div
                v-for="item in coupons.slice((currentPage-1)*perPage,(currentPage-1)*perPage+perPage)"
                :key="item.id"
                class="col-md-5"
            >
              <v-card
                  outlined
                  width="390"
              >
                <div class="offer-bg">
                  <img src="../../assets/images/feature.png" alt="img" width="15%">

                  <v-list-item three-line style="margin-top: -15%;">
                    <v-list-item-avatar
                        tile
                        width="150"
                        height="150"
                        style="border: 1px gray; margin: 20px"
                    >
                      <v-img :src="item.product_image" style="margin-left: auto; width: 50%; margin-right: auto"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <div class="coupon-title">
                        {{ item.name }}
                      </div>
                      <v-list-item-title class="mb-1">
                        <h2 class="price">
                          $ {{ item.price }}
                          <span class="discount">{{item.discount_percentage}} off</span>
                        </h2>
                      </v-list-item-title>
                      <v-list-item-subtitle class="description">
                        {{ item.description }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                  </v-list-item>
                </div>
                <v-card-text class="expires-bg">
                  <div class="flex flex-row" style="justify-content: space-between">
                    <div>
                      <h2 class="expires">
                        Expires:
                        <span class="text-black">{{formatDate(item.expire_date)}}</span>
                      </h2>
                    </div>
                    <div>
                      <h2 class="by">
                        By:
                        <span class="text-black">{{retailer}}</span>
                      </h2>
                    </div>
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions
                    class="button-bg"
                >
                  <div>
                    <v-btn
                        block
                        depressed
                        class="button"
                        text
                        @click="getDeal(item.name, item.price,item.product_image, item.description, item.expiry_date, item.discount,  item.advertiser_name)"
                    >
                      <img src="../../assets/svg/getdeal.svg" />
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>
            </div>
          </div>
          <div class="row" style="width: 950px;" v-else-if="coupons.length === 0">
            <div
                v-for="item in items.slice((currentPage-1)*perPage,(currentPage-1)*perPage+perPage)"
                :key="item.id"
                class="col-md-5"
            >
              <v-card
                  outlined
                  width="390"
              >
                <div class="offer-bg">
                  <img src="../../assets/images/feature.png" alt="img" width="15%">

                  <v-list-item three-line style="margin-top: -15%;">
                    <v-list-item-avatar
                        tile
                        width="150px"
                        height="120px"
                        style="border: 1px gray;"
                    >
                      <v-img :src="item.avatar" style="margin-left: 15%; height: 50px"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <div class="coupon-title">
                        {{ item.name }}
                      </div>
                      <v-list-item-title class="mb-1">
                        <h2 class="price">
                          $ {{ item.price }}
                          <span class="discount">{{item.discount_percentage}} off</span>
                        </h2>
                      </v-list-item-title>
                      <v-list-item-subtitle class="description">
                        {{ item.description }}
                      </v-list-item-subtitle>
                    </v-list-item-content>

                  </v-list-item>
                </div>
                <v-card-text class="expires-bg">
                  <div class="flex flex-row" style="justify-content: space-between">
                    <div>
                      <h2 class="expires">
                        Expires:
                        <span class="text-black">{{formatDate(item.expire_date)}}</span>
                      </h2>
                    </div>
                    <div>
                      <h2 class="by">
                        By:
                        <span class="text-black">{{retailer}}</span>
                      </h2>
                    </div>
                  </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions
                    class="button-bg"
                >
                  <div>
                    <v-btn
                        block
                        depressed
                        class="button"
                        text
                        @click="getDeal(item.name, item.price,item.product_image, item.description, item.expiry_date, item.discount,  item.advertiser_name)"
                    >
                      <img src="../../assets/svg/getdeal.svg" />
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>
            </div>
          </div>
          <div
              v-else
              class="w-full p-2 flex justify-center text-gray-500"
          >
            <h6>No data found</h6>
          </div>
          <div v-if="items.length > 0" class="float-right">
            <b-pagination
                v-model="currentPage"
                class="ml-lg-5 mt-3 float-end"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
            />
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
// import RetailerSidebar from "@/layout/RetailerSidebar";
import Header from "@/layout/Header";
import axios from "axios";
import moment from "moment";

export default {
  name: "Retailer",
  // components: {RetailerSidebar},
  components:{
    AppHeader: Header
  },
  props: ["name", "logo", "phone"],
  data() {
    return {
      retailer: 'lenscrafters',
      perPage: 4,
      center: {lat: this.$route.params.lat, lng: this.$route.params.lng},
      markers: [
        {
          position: {
            lat: this.$route.params.lat,
            lng: this.$route.params.lng,
          },
        }
      ],

      currentPage: 1,
      appHeaderKey: Math.random() + 1,
      loading: true,
      coupons: []
    }
  },
  methods:{
    fetchCoupons() {
      axios({
        method: "get",
        url: `${this.$store.state.baseURL}/api/coupons`,
      })
          .then((response) => {
            this.coupons = response.data.slice(0, 16);
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
            this.loading = false;
          });
    },
    getDeal(title,price, avatar, description, expiry, discount, advertiser_name) {
      this.$router.push({name: 'InStore', params: {
        title: title,
          price: price,
          image: avatar,
          description: description,
          expiry: expiry,
          discount: discount,
          retailer: advertiser_name
        }})
    },
    reRenderRouterView() {
      this.routerViewKey++;
    },
    showLocationModal() {
      this.dialog = true;
    },
    hideLocationModal() {
      this.dialog = false;
    },
    reRenderAppheader() {
      this.appHeaderKey++;
    },
    formatDate(date) {
      return moment(date).format('M/d/YYYY');
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    }
  },
  computed:{
    items() {
      return this.$store.state.items
    },
    rows() {
      return this.coupons.length || this.items.length
    }
  },
  mounted() {
    this.fetchCoupons()
    // this.geolocate();
  }
}
</script>

<style scoped>
.page-title{
  margin: 0 42px 24px 0;
  font-family: Lato,serif;
  font-size: 30px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.73;
  letter-spacing: normal;
  text-align: left;
  color: #1d1d1d;
}
.small-title{
  opacity: 0.6;
  font-family: Lato,sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: 0.14px;
  text-align: left;
  color: #1d1d1d;
}
.coupon-title{
  margin: 0 23px 10px 0;
  font-family: Lato, sans-serif;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #202020;
}
.price{
  margin: 10px 10px 13px 0;
  font-family: Lato,sans-serif;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #1d1d1d;
}
.discount{
  margin: 14px 36px 14px 10px;
  font-family: Lato,sans-serif;
  font-size: 14px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #ff7433;
}
.description{
  margin: 5px 0 0 1px;
  font-family: HelveticaNeue, sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}
.offer-bg{
  width: 390px;
  height: 152px;
  margin: 0 35px 0 0;
  padding: 1.6px 28px 20.5px 1.9px;
  border: solid 1px #e0e0e0;
  background-color: #fff;
}
.expires-bg{
  width: 390px;
  height: 38px;
  padding: 11px 20px;
  border: solid 1px #e0e0e0;
  background-color: #f6f6f8;
}
.button-bg{
  width: 390px;
  height: 70px;
  padding: 15px 21px;
  border: solid 1px #e0e0e0;
  background-color: #2e2e2e;
}
.button{
  width: 348px;
  height: 40px;
  padding: 9px 0 0 139px;
  border-radius: 4px;
}
.expires{
  margin: 0 57px 0 0;
  font-family: Lato, sans-serif;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #f17338;
}
.by{
  font-family: Lato,sans-serif;
  font-size: 13px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #f17338;
}
.logo-bg{
  width: 267px;
  height: 175px;
  padding: 31px 29px 27px 28px;
  border-radius: 4px;
  border: solid 1px #e5e5e5;
  background-color: #fff;
}
.logo{
  margin-top: auto;
  width: 50%;
  margin-right: auto;
  margin-left: auto;
}
.retailer-title{
  font-family: Lato, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.retailer-phone{
  font-family: Lato,sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #f17338;
}
.about-text{
  font-family: Lato,sans-serif;
  font-size: 16px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.79;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.full-desc{
  font-family: Lato,sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
.website{
  font-family: Lato,sans-serif;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #f17338;
}
</style>
