<template>
  <footer>
    <div class="bg-gray-dark text-white py-10" style="margin-top: 50px;">
      <div class="container">
        <div class="flex flex-wrap justify-between">
          <div class="flex flex-wrap">
            <div class="w-full md:w-1/3 p-4 text-left md:text-left">
              <h4 class="text-base font-semibold">Company</h4>
              <ul class="space-y-2 mt-2" style="list-style: circle">
                <li><a href="https://www.thedashshop.com/blog/community">In Your Community</a></li>
                <li><a href="https://www.thedashshop.com/blog/about" target="_blank">Blog</a></li>
                <li><a href="https://www.thedashshop.com/blog/jobs" target="_blank">Jobs</a></li>
                <li><a href="https://www.thedashshop.com/blog/contact" target="_blank">Contact</a></li>
              </ul>
            </div>
            <div class="w-full md:w-1/3 p-4 text-left md:text-left">
              <h4 class="text-base font-semibold" >Work with DashShop</h4>
              <ul class="space-y-2 mt-2" style="list-style: circle">
                <li><a href="https://www.thedashshop.com/blog/join" target="_blank">Join TheDashShop</a></li>
                <li><a href="https://www.thedashshop.com/blog/faq" target="_blank">How it Works</a></li>
                <li><a href="https://www.thedashshop.com/blog/advertisers" target="_blank">Advertisers</a></li>
                <li><a href="https://www.thedashshop.com/blog/affliate-programs" target="_blank">Affiliate Program</a></li>
                <li><a href="https://www.thedashshop.com/blog/vendors-program" target="_blank">Vendors Code of Conduct</a></li>
              </ul>
            </div>
            <div class="w-full md:w-1/3 p-4 text-left md:text-left">
              <h4 class="text-base font-semibold">More</h4>
              <ul class="space-y-2 mt-2" style="list-style: circle">
                <li><a href="https://www.thedashshop.com/blog/customer-support" target="_blank">Customer Support</a></li>
                <li><a href="https://www.thedashshop.com/blog/faq" target="_blank">FAQ</a></li>
                <li><a href="https://www.thedashshop.com/blog/coupon-codes" target="_blank">Coupon Codes</a></li>
                <li><a href="https://www.thedashshop.com/blog/how-it-works" target="_blank">How It Works</a></li>
                <li><a href="https://www.thedashshop.com/blog/contact" target="_blank">Contact Us</a></li>
              </ul>
            </div>
          </div>
          <div class="w-full md:w-1/4 p-4 flex flex-wrap justify-center">
            <h4 class="text-base font-semibold">DashCoupon Kiosk Locations</h4>
            <div
                class="inline-block relative mt-2 w-full"
            >
              <select
                class="block appearance-none w-full text-gray-900 bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              >
                <option value="0">Choose a City</option>
                <option v-for="city in cities" :key="city.id" :value="city.id">{{city.name}}</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path
                    d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                  />
                </svg>
              </div>
            </div>
            <div
              class="mt-4 flex items-center space-x-6 px-8 py-4 bg-burning-orange-500 hover:bg-burning-orange-600 rounded cursor-pointer"
            >
              <div
                class="flex items-center justify-center h-12 w-12 bg-white rounded-full border text-gray-600"
              >
                <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                  ></path>
                </svg>
              </div>
              <div>
                <h4 class="text-base font-semibold">Submit a Coupon</h4>
                <p>Share your code discount's everyone</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-gray-darker text-white py-8">
      <div class="container mx-auto">
        <div class="flex flex-wrap flex-row">
          <div class="w-full md:w-1/2 flex flex-wrap justify-center p-2">
          <div class="w-full md:w-1/2">
            <img
              class="w-full"
              src="@/assets/images/dashshop_Logo.png"
              alt="Dash Shop"
            />
          </div>
          <div class="w-full md:w-1/2 items-center px-2">
            <p class="text-xs">
              We have our very own fleet of delivery vans. Your order will be
              packed with care at warehouse and delivered right to your door.
            </p>
            <p class="mt-4 text-xs">
              &copy; {{ new Date().getFullYear() }} DashCoupons. All Rights
              Reserved!
            </p>
          </div>
          </div>
          <div class="w-full md:w-1/2 items-center text-center md:text-left">
            <h3>Follow Us</h3>
            <ul class="flex space-x-6 mt-2">
              <!-- Twitter -->
              <li class="flex-shrink-0">
                <a
                  class="flex items-center justify-center h-8 w-8 rounded-full border border-white"
                  href="https://twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    class="w-5 h-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path
                      d="M17.3 6.2c0 0.2 0 0.3 0 0.5 0 5-3.8 10.7-10.7 10.7 -2.1 0-4.1-0.6-5.8-1.7 0.3 0 0.6 0.1 0.9 0.1 1.8 0 3.4-0.6 4.7-1.6 -1.7 0-3-1.1-3.5-2.6 0.2 0 0.5 0.1 0.7 0.1 0.3 0 0.7 0 1-0.1C2.8 11.2 1.5 9.7 1.5 7.8c0 0 0 0 0 0 0.5 0.3 1.1 0.5 1.7 0.5 -1-0.7-1.7-1.8-1.7-3.1 0-0.7 0.2-1.3 0.5-1.9C3.9 5.5 6.7 7 9.9 7.2 9.8 6.9 9.8 6.6 9.8 6.3c0-2.1 1.7-3.8 3.8-3.8 1.1 0 2.1 0.5 2.8 1.2 0.9-0.2 1.7-0.5 2.4-0.9 -0.3 0.9-0.9 1.6-1.7 2.1 0.8-0.1 1.5-0.3 2.2-0.6C18.7 5.1 18.1 5.7 17.3 6.2z"
                      fill="#FFF"
                    />
                  </svg>
                </a>
              </li>
              <!-- Instagram -->
              <li
                class="flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-full border border-white"
              >
                <a
                  class="flex items-center justify-center h-8 w-8 rounded-full border border-white"
                  href="https://instagram.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                    class="w-4 h-4"
                  >
                    <path
                      d="M17.946 5.29a6.606 6.606 0 00-.418-2.185 4.412 4.412 0 00-1.039-1.594A4.412 4.412 0 0014.895.472a6.606 6.606 0 00-2.184-.418C11.75.01 11.444 0 9 0S6.25.01 5.29.054a6.606 6.606 0 00-2.185.418A4.412 4.412 0 001.51 1.511 4.412 4.412 0 00.472 3.105a6.606 6.606 0 00-.418 2.184C.01 6.25 0 6.556 0 9s.01 2.75.054 3.71a6.606 6.606 0 00.418 2.185 4.412 4.412 0 001.039 1.594 4.411 4.411 0 001.594 1.039 6.606 6.606 0 002.184.418C6.25 17.99 6.556 18 9 18s2.75-.01 3.71-.054a6.606 6.606 0 002.185-.418 4.602 4.602 0 002.633-2.633 6.606 6.606 0 00.418-2.184C17.99 11.75 18 11.444 18 9s-.01-2.75-.054-3.71zm-1.62 7.347a4.978 4.978 0 01-.31 1.67 2.98 2.98 0 01-1.708 1.709 4.979 4.979 0 01-1.671.31c-.95.043-1.234.052-3.637.052s-2.688-.009-3.637-.052a4.979 4.979 0 01-1.67-.31 2.788 2.788 0 01-1.036-.673 2.788 2.788 0 01-.673-1.035 4.978 4.978 0 01-.31-1.671c-.043-.95-.052-1.234-.052-3.637s.009-2.688.052-3.637a4.979 4.979 0 01.31-1.67 2.788 2.788 0 01.673-1.036 2.788 2.788 0 011.035-.673 4.979 4.979 0 011.671-.31c.95-.043 1.234-.052 3.637-.052s2.688.009 3.637.052a4.979 4.979 0 011.67.31 2.788 2.788 0 011.036.673 2.788 2.788 0 01.673 1.035 4.979 4.979 0 01.31 1.671c.043.95.052 1.234.052 3.637s-.009 2.688-.052 3.637zM9 4.378A4.622 4.622 0 1013.622 9 4.622 4.622 0 009 4.378zM9 12a3 3 0 113-3 3 3 0 01-3 3zm5.884-7.804a1.08 1.08 0 11-1.08-1.08 1.08 1.08 0 011.08 1.08z"
                      fill="#fff"
                    />
                  </svg>
                </a>
              </li>
              <!-- Facebook -->
              <li
                class="flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-full border border-white"
              >
                <a
                  class="flex items-center justify-center h-8 w-8 rounded-full border border-white"
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                    class="w-4 h-4"
                  >
                    <path
                      d="M17 1H3C1.9 1 1 1.9 1 3v14c0 1.1 0.9 2 2 2h7v-7H8V9.5h2v-2c0-2.2 1.2-3.7 3.8-3.7l1.8 0v2.6h-1.2C13.4 6.4 13 7.1 13 7.8v1.7h2.6L15 12h-2v7h4c1.1 0 2-0.9 2-2V3C19 1.9 18.1 1 17 1z"
                      fill="#FFF"
                    />
                  </svg>
                </a>
              </li>
              <!-- LinkedIn -->
              <li
                class="flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-full border border-white"
              >
                <a
                  class="flex items-center justify-center h-8 w-8 rounded-full border border-white"
                  href="https://linkedin.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 18"
                    class="w-4 h-4"
                  >
                    <path
                      d="M1 6h4v13H1V6zM3 1C1.8 1 1 2 1 3.1 1 4.1 1.8 5 3 5c1.3 0 2-0.9 2-2C5 1.9 4.2 1 3 1zM14.6 6.2c-2.1 0-3.3 1.2-3.8 2h-0.1l-0.2-1.7H6.9C6.9 7.6 7 8.9 7 10.4V19h4v-7.1c0-0.4 0-0.7 0.1-1 0.3-0.7 0.8-1.6 1.9-1.6 1.4 0 2 1.2 2 2.8V19h4v-7.4C19 7.9 17.1 6.2 14.6 6.2z"
                      fill="#FFF"
                    />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return{
      cities: []
    }
  },
  methods:{
    getCities() {
      axios({
        method: "get",
        url: 'http://18.237.231.25:5000/api/states',
      })
          .then((response) => {
            this.cities = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
            this.loading = false;
          });
    }
  },
  mounted() {
    this.getCities();
  }
}
</script>
