<template>
  <div class="space-y-8">
    <div class="border"
         style="min-width: 265px; min-height: 610px;width: 265px; height: 610px; max-width: 265px; max-height: 610px">
      <div class="bg-white">
        <img :src="retailer.logo" :alt="retailer.name" class="object-fill h-48 w-full p-2"/>
      </div>
      <div class="p-4 bg-gray-dark-light text-white">
        <h2 class="md:text-2xl text-lg font-semibold">{{ retailer.name }}</h2>
        <p class="font-semibold">
          <span class="text-burning-orange-500">Phone:</span> {{ retailer.phone }}
        </p>
        <hr class="my-4 border-gray-700"/>
        <p class="md:text-xl text-lg font-semibold text-white">About {{ retailer.name }}</p>
        <p class="mt-2 overflow-y-auto overflow-x-hidden break-words text-white"
           style="min-height: 120px; height: 180px; max-height: 180px">
          {{ retailer.companyDetail }}
        </p>
      </div>
      <div class="px-4 py-2 ">
        <p class="text-gray-900 font-semibold md:text-lg text-sm">
          <span class="text-burning-orange-500">Website:</span>
          lenscrafters.com
        </p>
      </div>
      <div>
        <img src="../assets/map.png" alt="map">
      </div>
    </div>
  </div>
</template>

<script>
import HelpersMixin from "@/mixins/Helpers";

export default {
  mixins: [HelpersMixin],
  // computed: {
  //   retailers() {
  //     return this.$index.state.retailers;
  //   },
  //   tags() {
  //     return this.$index.state.tags;
  //   },
  //   categories() {
  //     return this.$index.state.categories;
  //   },
  // },
  // props: {
  //   retailer: {
  //     logo: Image,
  //     name: String,
  //     phone: String,
  //     companyDetail: String
  //   }
  // },
  // props: {
  //   selectedRetailers:{
  //     logo: {
  //       type: String,
  //       required: true
  //     },
  //     name: {
  //       type: String,
  //       required: true
  //     },
  //     companyDetail: {
  //       type: String,
  //       required: true
  //     },
  //     location: {
  //       type: String,
  //       required: true
  //     },
  //   }
  // },
  data() {
    return {
      retailer:
          {
            logo: require('../assets/svg/lenscrafters.svg'),
            name: 'lens Crafters',
            phone: '+14455889302',
            companyDetail: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. ' +
                'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,' +
                ' when an unknown printer took a galley of type and scrambled it to make a type specimen book. '
          }
    }
  }
};
</script>
