import Vue from "vue";
import VueRouter from "vue-router";

import Main from './layout/Main';

import Home from "./pages/Home";
import Featured from "./pages/Featured";
import Local from "./pages/Local";
import National from "./pages/National";
import Retailer from "./pages/Retailer";
import Retailers from "./pages/Retailers";
import Microsite from "./pages/Microsite";
import Retail from  './pages/microsite/Retailer'
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Retaileroffer from "./pages/Retaileroffer";
import Productpurchase from "./pages/Productpurchase";
import ShippingInfo from "./pages/ShippingInfo";
import Payment from "./pages/order/Payment";
import Shippingconfirmation from "./pages/Shippingconfirmation";
import Category from "./pages/Category";
import SpecificCategoryPage from "./pages/SpecificCategoryPage";
import Checkout from "./pages/order/Checkout";
import Checkout1 from "./pages/order/Checkout-1";
import Checkout2 from "./pages/order/Checkout-2";
import Checkout3 from "./pages/order/Checkout-3";
import InStore from "./pages/order/InStore";
import Final from "./pages/order/Final";
import Cart from "./pages/Cart";
import PaidCoupon from "@/pages/microsite/PaidCoupon";
import PaidLocalCoupon from "@/pages/PaidLocalCoupon";
import InStore1 from "@/pages/order/InStore1";
import Instore2 from "@/pages/order/Instore2";
import InStore3 from "@/pages/order/InStore3";
import PaidNational from "@/pages/PaidNational";
import PaidFeatured from "@/pages/PaidFeatured";
import FreeCoupon from "@/pages/microsite/FreeCoupon";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Main,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home,
        meta: { scrollToTop: true }
      },
      {
        path: "featured",
        name: "Featured",
        component: Featured,
        meta: { scrollToTop: true }
      },
      {
        path: "local",
        name: "Local",
        component: Local,
        props: true,
        meta: { scrollToTop: true }
      },
      {
        path: "national",
        name: "National",
        component: National,
        meta: { scrollToTop: true }
      },
      {
        path: "retailers",
        name: "Retailers",
        component: Retailers,
        meta: { scrollToTop: true }
      },
      {
        path: "microsite/retailer/:id",
        name: "Microsite-Retailer",
        component: Retailer,
        props: true,
        meta: { scrollToTop: true }
      },
      {
        path: "retailers/retailer/:id",
        name: "Retailer",
        component: Retail,
        props: true,
        meta: { scrollToTop: true }
      },
      {
        path: "order/instore-1",
        name: "Instore1",
        component: InStore1,
        meta: { scrollToTop: true }
      },
      {
        path: "order/instore-2",
        name: "InStore2",
        component: Instore2,
        meta: { scrollToTop: true }
      },
      {
        path: "order/instore-3",
        name: "InStore3",
        component: InStore3,
        meta: { scrollToTop: true }
      },
      {
        path: "order/checkout",
        name: "Checkout",
        component: Checkout,
        meta: { scrollToTop: true }
      },
      {
        path: "order/checkout-1",
        name: "Checkout1",
        component: Checkout1,
        meta: { scrollToTop: true }
      },
      {
        path: "order/checkout-2",
        name: "Checkout2",
        component: Checkout2,
        meta: { scrollToTop: true }
      },
      {
        path: "order/checkout-3",
        name: "Checkout3",
        component: Checkout3,
        meta: { scrollToTop: true }
      },
      {
        path: "order/instore",
        name: "InStore",
        component: InStore,
        props: true,
        meta: { scrollToTop: true }
      },
      {
        path: "order/checkout/final",
        name: "Final",
        component: Final,
        meta: { scrollToTop: true }
      },
      {
        path: "local/paid",
        name: "PaidLocal",
        component: PaidLocalCoupon,
        meta: { scrollToTop: true }
      },
      {
        path: "national/paid",
        name: "PaidNational",
        component: PaidNational,
        meta: { scrollToTop: true }
      },
      {
        path: "featured/paid",
        name: "PaidFeatured",
        component: PaidFeatured,
        meta: { scrollToTop: true }
      },
      {
        path: "order/pay",
        name: "Payment",
        component: Payment,
        meta: { scrollToTop: true }
      },
      {
        path: "microsite/:id",
        name: "Microsite",
        component: Microsite,
        meta: { scrollToTop: true }
      },
      {
        path: "microsite/paid/:id",
        name: "PaidCoupon",
        props: true,
        component: PaidCoupon,
        meta: { scrollToTop: true }
      },
      {
        path: "microsite/free/:id",
        name: "FreeCoupon",
        props: true,
        component: FreeCoupon,
        meta: { scrollToTop: true }
      },
      {
        path: "retaileroffer",
        name: "Retaileroffer",
        component: Retaileroffer,
        meta: { scrollToTop: true }
      },
      {
        path: "productpurchase",
        name: "Productpurchase",
        component: Productpurchase,
        meta: { scrollToTop: true }
      },
      {
        path: "shippinginfo",
        name: "Shippinginfo",
        component: ShippingInfo,
        meta: { scrollToTop: true }
      },
      // {
      //   path: "payment",
      //   name: "Payment",
      //   component: Payment,
      //   meta: { scrollToTop: true }
      // },
      {
        path: "shippingconfirmation",
        name: "Shippingconfirmation",
        component: Shippingconfirmation,
        meta: { scrollToTop: true }
      },
      {
        path: "category",
        name: "Category",
        component: Category,
        meta: { scrollToTop: true }
      },
      {
        path: "category/:categoryname",
        name: "SpecificCategoryPage",
        component: SpecificCategoryPage,
        meta: { scrollToTop: true }
      },
      {
        path: "cart",
        name: "Cart",
        component: Cart,
        meta: { scrollToTop: true }
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { scrollToTop: true }
  },
  {
    path: "/signup",
    name: "Register",
    component: Register,
    meta: { scrollToTop: true }
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
