<template>
  <div>
    <div class="container mx-auto pt-4 pb-8">
      <div class="flex mt-4 space-x-8" style="height: 350px">
        <div
          class="relative h-full sm:w-2/3  w-1/3 bg-white flex items-center justify-center flex-shrink-0 border"
        >
          <img
            :src="getImageUrl('coupon-img-lenscrafters@2x.png')"
            alt="Versace"
          />
        </div>
        <div
          class="flex-auto flex flex-col bg-white border max-h-full overflow-hidden"
        >
          <div class="p-4">
            <h2 class="text-xl sm:text-base  sm:font-normal font-semibold leading-tight">
             Most stylish lens out there, with exclusive tech perks and state
              of the art features
            </h2>
            <div class="flex justify-between items-baseline mt-2">
              <p class="font-semibold">
                <span class="text-burning-orange-500">By:</span> LensCrafters
              </p>
              <p class="font-semibold">
                <span class="text-2xl">$9.99</span>
                <span class="ml-2 text-burning-orange-500">50% Off</span>
              </p>
            </div>
          </div>
          <div
            class="bg-gray-200 flex items-center justify-center space-x-1 py-2 border-t border-b"
          >
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              ></path>
            </svg>
            <p class="font-semibold">
              <span class="text-burning-orange-500">Offer Expires:</span>
              <span class="ml-2">12/31/2020</span>
            </p>
          </div>
          <div class="bg-gray-dark px-4 py-3">
            <router-link to="/productpurchase">
              <button
                class=" block w-full px-6 py-2 text-xs bg-burning-orange-500 text-white font-semibold uppercase rounded hover:bg-burning-orange-600"
                type="button"
              >
                Get Deal
              </button>
            </router-link>
          </div>
          <div class="p-4 overflow-y-auto">
            <p class="font-semibold">Product Details</p>
            <p class="mt-2">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
              dolores minima recusandae fugiat, exercitationem eius, ex earum,
              obcaecati culpa doloremque voluptate. Reprehenderit laborum
              necessitatibus pariatur sapiente aspernatur voluptatum, doloribus
              molestiae.
            </p>
            <p class="mt-2 font-semibold">Specifications</p>
            <p class="mt-2">...</p>
          </div>
        </div>
      </div>
    </div>
    <div>
    





  </div>
  </div>
</template>
<style scoped>
  .marignForButton {
    margin-top: 20px;
    position: absolute;
    top:600px;
    max-height: 100px;
  }
</style>
<script>
import HelpersMixin from "@/mixins/Helpers";

export default {
  mixins: [HelpersMixin],
};
</script>
