<template>
  <div>
    <AppHeader :key="appHeaderKey" v-bind.sync="searchString" v-model="searchString" :callBack="reRenderRouterView"/>
    <div class="container mx-auto my-6" id="banner" v-if="showBanner">
      <div class="relative" style="height: 350px">
        <button id="x" v-on:click.once="toggleBanner">X</button>

        <img
            class="h-full w-full object-cover"
            src="@/assets/images/shopping.jpg"
            alt="Shopping"
        />
        <div
            class="
            absolute
            inset-0
            flex
            items-center
            justify-center
            bg-orange-500 bg-opacity-50
          "
        >
          <div class="text-center">
            <h2
                class="
                w-full
                max-w-2xl
                mb-8
                text-white text-4xl
                font-semibold
                leading-tight
              "
            >
              Earn In-Store Discounts when you redeem in person
            </h2>
            <button
                type="button"
                class="
                px-4
                py-2
                bg-burning-orange-500
                text-white
                font-semibold
                rounded
              "
            >
              How It Works
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="container mx-auto my-6">
      <div class="flex flex-col justify-center items-center leading-tight">
        <h2 class="text-2xl"><strong>Browse Coupon</strong> Retailers</h2>
        <p class="text-gray-600 font-semibold">
          Discover discount saving from top retailers.
        </p>
      </div>
      <div class="mt-6 pt-6 border-t">
        <div class="flex flex-wrap space-x-2 p-1 bg-gray-200">
          <button
              class="
              flex-1
              py-1
              px-4
              bg-burning-orange-500
              text-white
              font-semibold
            "
              type="button"
              v-on:click="changeCharacter('')"
          >
            All
          </button>
          <button
              v-for="i in 26"
              class="flex-1 py-1 hover:bg-gray-400"
              type="button"
              :key="i"
              v-on:click="changeCharacter(String.fromCharCode(i + 64))"
          >
            {{ String.fromCharCode(i + 64) }}
          </button>
          <button
              v-on:click="changeCharacter('')"
              class="flex-1 py-1 px-4 hover:bg-gray-400"
              type="button"
          >
            0-9
          </button>
        </div>
      </div>
      <section>
                <div class="flex justify-center p-4" v-if="loading">
                  <v-progress-circular indeterminate color="accent"></v-progress-circular>
                </div>
        <div
            class="mx-4 mt-6 grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 gap-4"
            v-else-if="retailers.length > 0"
        >
          <div v-for="(retailer, index) in retailers" :key="`retailer-${index}`">
            <router-link
                :to="{name: 'Retailer', params: {id: retailer.id, name: retailer.name, logo: retailer.logo, phone: retailer.phone_no, detail: retailer.contact_detail}}"
                v-if="retailer.name.startsWith(selectBy)"
            >
              <div
                  class="border h-48 flex items-center justify-center p-12"
              >
                <img
                    class="h-full w-full object-contain"
                    :src="retailer.logo"
                    @error="errorHandler"
                    :alt="retailer.name"
                />
              </div>
              <h2 class="text-black text-center font-bold mt-3" style="font-size: 1em">{{ retailer.name }}</h2>
            </router-link>
            </div>
        </div>
        <div
            class="mx-4 mt-6 grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 gap-4"
            v-else-if="retailers.length === 0"
        >
          <div v-for="(retailer, index) in selectedRetailers" :key="`retailer-${index}`">
            <router-link
                :to="{name: 'Retailer', params: {id: retailer.id, name: retailer.name, logo: retailer.logo, phone: retailer.phone, detail: retailer.description, lat: retailer.lat, lng: retailer.lng}}"
                v-if="retailer.name.startsWith(selectBy)"
            >
              <div
                  class="border h-48 flex items-center justify-center p-12"
              >
                <img
                    class="h-full w-full object-contain"
                    :src="retailer.logo"
                    @error="errorHandler"
                    :alt="retailer.name"
                />
              </div>
              <h2 class="text-black text-center font-bold mt-3" style="font-size: 1em">{{ retailer.name }}</h2>
            </router-link>
            </div>
        </div>
        <div
            v-else
            class="w-full flex p-6 justify-center text-gray-500"
        >
          <h6>No data found</h6>
        </div>
      </section>
      <div v-if="retailers.length > 0" class="float-right">
        <Pagination/>
      </div>
    </div>
  </div>
</template>
<style scoped>
#x {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #ffffff;
  z-index: 10;
}

#x:hover {
  color: red;
}
</style>

<script>
import Pagination from "../components/Pagination.vue";
import Header from "@/layout/Header";
import axios from "axios";
// import axios from "axios";

export default {
  components: {Pagination, AppHeader: Header},
  name: "banner",
  data: function () {
    return {
      showBanner: true,
      appHeaderKey: Math.random() + 1,
      selectBy: "",
      retailers: [],
      loading: true,
      state: localStorage.getItem("location")
          ? JSON.parse(localStorage.getItem("location")).state
          : "",
      city: localStorage.getItem("location")
          ? JSON.parse(localStorage.getItem("location")).city
          : "",
      zipCode: localStorage.getItem("location")
          ? JSON.parse(localStorage.getItem("location")).zipcode
          : "",
      searchString: localStorage.getItem("location")
          ? JSON.parse(localStorage.getItem("location")).searchString
          : "",
    };
  },
  mounted() {
    this.fetchRetailers()
  },
  methods: {
    toggleBanner: function () {
      this.showBanner = !this.showBanner;
    },
    changeCharacter: function (x) {
      this.selectBy = x === "All" ? "" : x;
      this.selectedRetailers = x === "All" ? this.retailers : this.retailers.filter(r => r.name.startsWith(x))
    },
    fetchRetailers() {
      axios({
        method: "get",
        url: `${this.$store.state.baseURL}/api/retailers`,
        data: {
          state: this.state,
          city: this.city,
          zipCode: this.zipcode,
          searchString: this.searchString,
          getFeatured: false,
          couponId: 0,
          category: "",
        },
      })
          .then((response) => {
            this.retailers = response.data;
            this.selectedRetailers = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
            this.loading = false;
          });
    },
    getpath(id) {
      return `/retailers/retailer/${id}`;
    },
    errorHandler(event) {
      event.target.src = `${this.$store.state.baseURL}/no_image.png`;
    },
    reRenderRouterView() {
      this.routerViewKey++;
    },
    showLocationModal() {
      this.dialog = true;
    },
    hideLocationModal() {
      this.dialog = false;
    },
    reRenderAppheader() {
      this.appHeaderKey++;
    },
  },
  computed: {
    selectedRetailers(){
      return this.$store.state.retailers
    }
  }
};
</script>
