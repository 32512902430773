<template>
  <div>
    <TopBar />
    <div>

      <router-view
          :key="routerViewKey"
          :micrositeSignInCallBack="reRenderAppheader"
      ></router-view>
    </div>
<!--    <v-row>-->
<!--      <v-dialog-->
<!--        v-model="dialog"-->
<!--        persistent-->
<!--        max-width="800px"-->
<!--        content-class="elevation-0 flex justify-center"-->
<!--      >-->
<!--        <v-card-->
<!--          class="rounded-circle p-8 hideScrollBar border-burning-orange-500"-->
<!--          style="-->
<!--            height: 90vw;-->
<!--            width: 90vw;-->
<!--            max-height: 480px;-->
<!--            max-width: 480px;-->
<!--            overflow: auto;-->
<!--            border: 5px solid #ff7433;-->
<!--          "-->
<!--        >-->
<!--          <v-card-title class="flex justify-center">-->
<!--            <div class="block font-bold text-4xl text-burning-orange-500">-->
<!--              Local Deals-->
<!--            </div>-->
<!--            <div-->
<!--              class="text-base inline-block text-center break-normal"-->
<!--              style="width: 100%"-->
<!--            >-->
<!--              Save up to 50% of 1000s Awesome <br />-->
<!--              Deals Near You-->
<!--            </div>-->
<!--          </v-card-title>-->
<!--          <v-card-text class="p-0">-->
<!--            <v-container class="flex justify-center">-->
<!--              <v-row style="max-width: 90%">-->
<!--                <v-col>-->
<!--                  <v-autocomplete-->
<!--                    v-model="zipcode"-->
<!--                    :items="zipCodes"-->
<!--                    :search-input.sync="search"-->
<!--                    placeholder="Start typing to Search"-->
<!--                    flat-->
<!--                    hide-no-data-->
<!--                    hide-details-->
<!--                    color="black"-->
<!--                    label="Choose your location"-->
<!--                  >-->
<!--                    <template v-slot:selection="data">-->
<!--                      <v-chip :input-value="data.selected">-->
<!--                        {{ data.item }}-->
<!--                      </v-chip>-->
<!--                    </template>-->
<!--                  </v-autocomplete>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </v-container>-->
<!--          </v-card-text>-->
<!--          <v-card-actions class="flex justify-center flex-wrap">-->
<!--            <div style="max-width: 100%" class="flex justify-center flex-wrap">-->
<!--              <v-btn-->
<!--                text-->
<!--                class="-->
<!--                  block-->
<!--                  w-full-->
<!--                  font-semibold-->
<!--                  bg-burning-orange-500-->
<!--                  hover:bg-burning-orange-600-->
<!--                  rounded-->
<!--                  w-24-->
<!--                "-->
<!--                @click="onLocationSave()"-->
<!--                max-width="200px"-->
<!--              >-->
<!--                Get Local Deals-->
<!--              </v-btn>-->
<!--              <div style="max-width: 70%" class="block p-1 text-center m-0">-->
<!--                <p class="text-xs m-0">-->
<!--                  Yes I want to save money by receiving personalized DashShop-->
<!--                  notifications with awesome deals. By entering location I agree-->
<!--                  to the Terms of Use and have read the privacy statement.-->
<!--                </p>-->
<!--              </div>-->
<!--              <v-btn-->
<!--                color="blue darken-1"-->
<!--                text-->
<!--                @click="dialog = false"-->
<!--                block-->
<!--                style="max-width: 200px !important"-->
<!--              >-->
<!--                No Thanks-->
<!--              </v-btn>-->
<!--            </div>-->
<!--          </v-card-actions>-->
<!--        </v-card>-->
<!--      </v-dialog>-->
<!--    </v-row>-->
    <div>
      <App />
    </div>
    <div>
      <AppFooter />
    </div>

  </div>
</template>
<style scope>
.hideScrollBar::-webkit-scrollbar {
  display: none;
}
.hideScrollBar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
<script>
import axios from "axios";
// import Header from "@/layout/Header";
import TopBar from "@/layout/TopBar";
// import QuickLinks from "@/layout/QuickLinks";
import Footer from "@/layout/Footer";
import HelpersMixin from "@/mixins/Helpers";
export default {
  mixins: [HelpersMixin],
  components: {
    TopBar,
    // AppHeader: Header,
    // QuickLinks,
    AppFooter: Footer,
  },
  props:{
    searchString: String
  },
  data: function () {
    return {
      // searchString: "",
      // location: { state: "", city: "", zipcode: "", searchString: "" },
      zipcode: "",
      city: "",
      selectedState: "",
      dialog: false,
      search: null,
      zipCodes: [],
      routerViewKey: Math.random() + 1,
      loading: false,
      appHeaderKey: Math.random() + 1,
      zipcodeRules: [
        (v) => !!v || "Zipcode is required",
        (v) =>
          (v && v.length <= 10) || "Zipcode must be less than 10 characters",
      ],
    };
  },
  watch: {
    search(val) {
      console.log(val);
      val && this.fetchZipCodes(val);
    },
  },
  methods: {

    fetchZipCodes(value) {
      axios({
        method: "get",
        url: `${this.$store.state.baseURL}/api/Coupons/GetZipcodes?SearchString=${value}`,
      })
        .then((response) => {
          this.loading = true;
          this.zipCodes = response.data.map(
            (d) => `${d.state}, ${d.city}, ${d.zip_code}`
          );
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
          this.loading = false;
        });
    },
    onLocationSave() {
      if (this.zipcode) {
        const loc = this.zipcode.split(", ");
        this.location.state = loc[0];
        this.location.city = loc[1];
        this.location.zipcode = loc[2];
        this.location.searchString = this.searchString;
        localStorage.setItem("location", JSON.stringify(this.location));
        this.reRenderRouterView();
        this.hideLocationModal();
      }
    },
    fetchGeoLocation() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          axios({
            method: "get",
            url: `http://api.positionstack.com/v1/reverse?access_key=df3de8a76c1377e1b3095987c4b24ddb&query=${position.coords.latitude},${position.coords.longitude}`,
            headers: {"Access-Control-Allow-Origin": "*"}
          }).then((response) => {
            console.log(response.data.data[0].locality);
            this.location.city = response.data.data[0].locality
            localStorage.setItem("location", JSON.stringify(this.location));
            this.reRenderRouterView()
            if(response.data.data[0].locality){
              this.hideLocationModal()
            }
            else{
              this.showLocationModal();
            }
          });
        },
        (error) => {
          console.log(error.message);
        }
      );
    },

    reRenderRouterView() {
      this.routerViewKey++;
    },
    showLocationModal() {
      this.dialog = true;
    },
    hideLocationModal() {
      this.dialog = false;
    },
    reRenderAppheader() {
      this.appHeaderKey++;
    },
  },
  mounted() {
    if (!localStorage.location) {
      this.fetchGeoLocation();
    }
    console.log(window.location.href.split( "?" )[ 0 ].includes('localhost'))
  },
  computed: {
    states() {
      return this.$store.state.states;
    },
  },
};
</script>
