<template>
        <div class="my-6 flex justify-end">
          <div class="flex space-x-1">
            <div
              class="p-1 bg-gray-600 hover:bg-gray-900 cursor-pointer text-white"
            >
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 19l-7-7 7-7"
                ></path>
              </svg>
            </div>
            <div
              class="p-1 text-gray-600 hover:text-gray-900 font-semibold cursor-pointer"
            >
              <span class="w-6 h-6 inline-flex justify-center items-center">
                1
              </span>
            </div>
            <div
              class="p-1 bg-gray-600 hover:bg-gray-900 cursor-pointer text-white"
            >
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                ></path>
              </svg>
            </div>
          </div>
        </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    item: {image: String, title: String  , expires: String,  by: String}
  }
}
</script>