<template>
  <div>
    <AppHeader :key="appHeaderKey" v-bind.sync="searchString" v-model="searchString" :callBack="reRenderRouterView"/>
    <div class="container mx-auto">
      <div>
        <div class="row" style="display: flex; flex-direction: column; margin-left: 10%">
          <div>
            <h1 class="checkout-text">Checkout (1 item)</h1>
          </div>
          <div>
            <p class="please-provide">
              Please provide the following information to complete your purchase
            </p>
          </div>
        </div>
        <div class="row bg">
          <!--checkout form -->
          <div class="col-md-6">

            <h2 class="contact-information">Contact Information</h2>
            <!--Contact form -->
            <div>
              <template>
                <v-card
                    class="contact-bg"
                    elevation="2"
                    outlined
                >
                  <v-card-text>
                    <div class="flex flex-row justify-content-between">
                      <h2 class="contact">Contact</h2>
                      <h5 class="contact-email">usercontact@gmail.com</h5>
                    </div>
<!--                    <div class="flex flex-row justify-content-between" style="margin-top: 18px">-->
<!--                      <h2 class="ship-to">Ship To:</h2>-->
<!--                      <h5 class="address">123 Address way</h5>-->
<!--                    </div>-->
                  </v-card-text>
                </v-card>
              </template>
            </div>
            <!--- Ship Method --->
<!--            <div>-->
<!--              <h1 class="shipping-method">Shipping Method</h1>-->
<!--              <h3 class="select-shipping">-->
<!--                Select your Shipping method-->
<!--              </h3>-->
<!--              <v-row>-->
<!--                <v-col>-->
<!--&lt;!&ndash;                  <select&ndash;&gt;-->
<!--&lt;!&ndash;                      name="City"&ndash;&gt;-->
<!--&lt;!&ndash;                      v-model="selectedValue"&ndash;&gt;-->
<!--&lt;!&ndash;                      style="width: 520px; height: 50px; margin: 14px 0 0; padding: 15px 20.7px 14px 20px; border-radius: 4px; border: solid 1px #d3d3d5; background-color: #fff;"&ndash;&gt;-->
<!--&lt;!&ndash;                  >&ndash;&gt;-->
<!--&lt;!&ndash;                    <option disabled value="">Please select one</option>&ndash;&gt;-->
<!--&lt;!&ndash;                    <option v-for="item in values" :value="item" :key="item.id">{{ item.name }}</option>&ndash;&gt;-->
<!--&lt;!&ndash;                  </select>&ndash;&gt;-->
<!--                  <v-select-->
<!--                      :items="values"-->
<!--                      label="Select Shipping"-->
<!--                      outlined-->
<!--                  ></v-select>-->
<!--                </v-col>-->
<!--              </v-row>-->
<!--            </div>-->
            <div class="justify-content-between">
              <router-link to="/order/instore">
                <v-btn
                    style="width: 240px; height: 50px; margin: 50px 30px 20px 0; padding: 10px 41px 11px 42px; border-radius: 8px; background-color: #fe7433; color: white"
                    depressed
                >
                  Pickup In-Store
                </v-btn>
              </router-link>
              <router-link to="/order/checkout-1">
                <button
                    style="float: right; width: 240px; color: #2b232f; height: 50px; margin: 50px 40px 0 0; padding: 10px 41px 11px 42px; border-radius: 8px; background-color: #ebebec;">
                  Delivery Services
                </button>
              </router-link>
            </div>
            <!-- return button -->
            <div class="mt-5 mb-5">
              <router-link to="/microsite/paid">
                <button
                    class="return-btn flex flex-row"
                >
                  <svg
                      style="color: #0077d9; width: 7.9px; height: 15.7px; margin: 5.2px 12.1px 5px 0;"
                      xmlns="http://www.w3.org/2000/svg"
                      width="18" height="18" fill="currentColor"
                      class="bi bi-chevron-left"
                      viewBox="0 0 18 18">
                    <path fill-rule="evenodd"
                          d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                          fill="blue"></path>
                  </svg>
                  Return to Microsite
                </button>
              </router-link>
            </div>
          </div>
          <div class="col-md-4 ml-2" style="width: 430px; height: 690px; margin: 25px 0 0;">
            <router-link to="/cart">
              <h2 class="text-right return-cart" style="margin-top: -20px">Return to cart</h2>
            </router-link>
            <template>
              <v-card-title class="order-summary">Order Summary</v-card-title>
              <v-card
                  class="mx-auto my-12"
              >

                <v-divider class="mx-4"></v-divider>
                <v-img
                    style="height: 238px; width: 370px; margin: 0 10px 18px; background-color: #2b232f"
                    src=""
                ></v-img>

                <v-card-text>
                  <v-row
                      align="center"
                      class="mx-0"
                  >
                    <div>
                      <h2 class="product-desc">
                        Product Description
                      </h2>
                      <p class="desc">
                        Lenscrafters get 1 Free Lens craters get 1 <br/>Free Lens crafters get 1 Free
                      </p>
                    </div>
                  </v-row>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <div>
                    <div style="display: flex; flex-direction: row; justify-content: space-between">
                      <h2 class="subtotal">Subtotal</h2>
                      <h2 class="ship-price">$24.55</h2>
                    </div>
<!--                    <div style="display: flex; flex-direction: row; justify-content: space-between">-->
<!--                      <h2 class="shipping-desc">Shipping</h2>-->
<!--                      <h2 class="next-step">Calculated at next step</h2>-->
<!--                    </div>-->
                  </div>
                </v-card-text>
                <v-card-text>
                  <div>
                    <div class="mt-4" style="display: flex; flex-direction: row; justify-content: space-between">
                      <h2 class="order-total">Order Total</h2>
                      <h3 class="order-price">
                        $24.55
                        <br/>
                        <span class="yousave">You save $24.55</span>
                      </h3>
                    </div>
                    <router-link to="/order/instore-2">
                      <v-card-actions>

                        <button
                            class="checkout-btn"
                        >
                          Proceed to Checkout
                        </button>
                      </v-card-actions>
                    </router-link>
                  </div>
                </v-card-text>
              </v-card>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Header from "@/layout/Header";
import axios from "axios";

export default {
  name: "Checkout",
  components: {
    AppHeader: Header
  },
  data: function () {
    return {
      appHeaderKey: Math.random() + 1,
      searchString: '',
      email: '',
      selectedValue: null,
      values: ['Standard', 'Express'],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
    }
  },
  mounted() {
    this.getCities()
  },
  methods: {
    getCities() {
      axios({
        method: "get",
        url: 'http://18.237.231.25:5000/api/states',
      })
          .then((response) => {
            this.cities = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
            this.loading = false;
          });
    },
    reRenderRouterView() {
      this.routerViewKey++;
    },
    showLocationModal() {
      this.dialog = true;
    },
    hideLocationModal() {
      this.dialog = false;
    },
    reRenderAppheader() {
      this.appHeaderKey++;
    },
  }
}
</script>

<style scoped>
.checkout-text {
  margin: 0 753px 15px 7px;
  font-family: Roboto, serif;
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  text-align: left;
  color: #202020;
}

.please-provide {
  margin: 8px 526px 11px 7px;
  opacity: 0.7;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.18px;
  text-align: left;
  color: #202020;
}

.contact-information {
  margin: 0 30px 28px 2px;
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.32px;
  text-align: left;
  color: #202020;
}

.bg {
  width: 1240px;
  height: 1120px;
  margin: 11px 0 0 10%;
  padding: 27px 32px 39px 30px;
  border-radius: 8px;
  background-color: #f6f6f8;
}

.shipping {
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  text-align: left;
  color: #202020;
}

.return-btn {
  width: 247px;
  height: 50px;
  margin: 20px 19px 0 10px;
  padding: 12px 10px 12px 10px;
  border-radius: 8px;
  border: solid 1px #dddddf;
  background-color: #fff;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: -0.2px;
  text-align: left;
  color: #0077d9;
}

.return-cart {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  text-align: left;
  color: #1673cb;
}

.order-summary {
  margin: 0 25px 25px 0;
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.24px;
  text-align: left;
  color: #202020;
}

.subtotal {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  text-align: left;
  color: #202020;
}

.shipping-desc {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  text-align: left;
  color: #202020;
}

.product-desc {
  margin: 18px 18px 10px 0;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  text-align: left;
  color: #202020;
}

.desc {
  margin: 0 0 0 0.5px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.13px;
  text-align: left;
  color: #202020;
}

.ship-price {
  float: right;
  margin: 0 0 10px 3px;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  text-align: left;
  color: #202020;
}

.next-step {
  float: right;
  margin: 0 0 16px 3px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  text-align: left;
  color: #202020;
}

.order-total {
  margin: 0 25px 5px 0;
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  text-align: left;
  color: #202020;
}

.order-price {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  text-align: right;
  color: #333;
}

.yousave {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  text-align: right;
  color: #fe7433;
}

.checkout-btn {
  width: 368px;
  height: 50px;
  margin: 27px 1px 0;
  padding: 10px 23px 11px 24px;
  border-radius: 8px;
  background-color: #fe7433;
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.95;
  letter-spacing: -0.22px;
  text-align: center;
  color: #fff;
}

.contact-bg {
  width: 534px;
  height: 150px;
  margin: 24px 215px 37px 0;
  padding: 28px 1.4px 32px 22px;
  border-radius: 10px;
  border: solid 1px #dcdfe1;
  background-color: #fff;
}

.contact {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  text-align: left;
  color: #202020;
}

.contact-email {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.13px;
  text-align: right;
  color: #202020;
}

.ship-to {
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: 0.2px;
  text-align: left;
  color: #202020;
}

.address {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.13px;
  text-align: right;
  color: #202020;
}

.shipping-method {
  height: 43px;
  margin: 0 21px 27px 0;
  font-family: Roboto, sans-serif;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.75;
  letter-spacing: 0.32px;
  text-align: left;
  color: #202020;
}

.select-shipping {
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.13px;
  text-align: left;
  color: #202020;
}
</style>
