<template>
  <div>
    <AppHeader :key="appHeaderKey" v-bind.sync="searchString" v-model="searchString" :callBack="reRenderRouterView"/>
    <div>
      <div style="background-color: #f6f6f8">
        <div class="container mx-auto">
          <div class="py-4">
            <h2 class="mt-4 text-xl md:ml-16">
              <strong>Hi there,</strong> treat yourself to a great discount
              saving today!
            </h2>
            <div
                class="
                flex flex-row
                justify-center
                mt-4
                md:ml-16
                h-full
                hidden
                md:inline-flex
              "
                style="max-height: 500px; min-height: 400px"
            >
              <div class="md:w-1/3 w-full h-full">
                <div
                    class="flex flex-col items-center bg-white border w-full"
                    style="height: 500px; width: 267px"
                >
                  <div
                      class="flex flex-row bg-burning-orange-500 p-2 w-full"
                      style="height: 30px"
                  >
                    <svg
                        class="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                      ></path>
                    </svg>
                    <span class="text-base pl-2 font-semibold">
                      Featured Retailers
                    </span>
                  </div>
                  <div class="container">
                    <div  v-for="retailer in retailers" :key="retailer.id">
                      <router-link
                          :to="{name: 'Retailer', params: {id: retailer.id, name: retailer.name, logo: retailer.logo, phone: retailer.phone, detail: retailer.description, lat: retailer.lat, lng: retailer.lng}}"
                          >
                        <div class="row">

                          <div class="col-md-5">
                            <img :src="retailer.logo" :alt="retailer.name" class="retailer-image"/>
                          </div>
                          <div class="col-md-6">
                            <h2 class="retailer-text">{{retailer.name}}</h2>
                          </div>
                        </div>
                      </router-link>

                    </div>
                    <div class="mt-2">
                      <v-row>
                        <v-col cols="12" class="flex flex-row justify-center">
                          <router-link
                              to="/retailers"
                              class="inline-flex
                                   items-center
                                   space-x-2
                                   text-gray-700
                                   hover:text-black
                                   "
                          >
                                                  <span class="font-semibold secondary--text"
                                                  >View All Retailers</span
                                                  >
                            <svg
                                class="w-6 h-6 text-burning-orange-500"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M9 5l7 7-7 7"
                              ></path>
                            </svg>
                          </router-link>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  class="md:w-2/3 ml-2"
                  style="
                  padding: 40px;
                  top: -40px;
                  position: relative;
                  height: 500px;
                  width: 850px;
                "
              >
                <video width controls autoplay loop muted>
                  <source
                      src="../assets/videos/video.mp4"
                      type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal size="lg" ref="my-modal" hide-footer hide-header centered>
      <div class="d-block text-center mt-5 mb-5">
        <div class="row">
          <div class="col-md-5">
            <img src="../assets/images/cart.png" style="margin-left: auto; margin-right: auto; width: 100%; margin-top: 20px; margin-bottom: 20px;" />
          </div>
          <div class="col-md-6">
            <h1 class="text-center" style="font-weight: bolder; font-size: 45px;">Welcome!</h1>
            <h2 style="font-weight: 900; margin-top: 5px; font-size: larger; border: 1px solid gray; border-radius: 25px; line-height: 40px; background-color: grey;">
              Enjoy great Discounts
            </h2>
            <div class="mt-5">
              <input type="text" style="border: 1px solid grey; border-radius: 15px;" placeholder="Enter Zip code" class="form-control" />
            </div>
            <div class="mt-3">
              <button class="btn btn-success form-control" style="border-radius: 10px; font-size: 20px; line-height: 30px;">Show my coupons</button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
      <!-- End of Featured Retailers Section -->
      <div class="container">
        <div class="flex flex-row justify-center mt-10">
        <v-sheet class="mx-4 p-4 w-full" max-width="1200">
          <h3 class="text-2xl text-center"><strong>Featured</strong> Offers</h3>
          <p class="text-gray-600 text-center">
            Discover the latest from your favorite Retailers.
          </p>
        </v-sheet>
      </div>
      <!-- <div class="container" style="width: 100%; margin-left: 12%">
        <FeaturedCarousel/>
      </div> -->
      <div class="
                flex flex-row
                justify-center
                mt-4
                h-full
                hidden
                md:inline-flex
              " style="width: 1280px;">
        <FeaturedCarousel/>
      </div>
      </div>
    
      <!-- Featured Offers Section -->

      <div class="py-10" style="background-color: #f6f6f8">
        <div class="container mx-auto">
          <h3 class="text-2xl text-center">
            <strong>What Are You</strong> Looking For Today
          </h3>
          <p class="text-gray-600 text-center">
            Enhance your shopping experience with categories.
          </p>
          <hr class="my-10"/>
          <Category style="width: 1200px" v-bind:onHeader="false"/>
        </div>
      </div>
    </div>
    <!-- Categories Section End -->
    <!-- <button @click="locatorButtonPressed">click me</button>
    <span>{{testLocation}}</span> -->

    <div class="bg-white py-10">
      <div class="container mx-auto" style="width: 1160px">
        <Ad/>
        <!-- End Promo Section -->

        <h3 class="text-2xl text-center mt-10">
          <strong>More Amazing</strong> Offers {{ info }}
        </h3>
        <p class="text-gray-600 text-center">
          Discover discount savings from top retailers.
        </p>
        <hr class="my-10"/>
<!--        <div class="flex justify-center p-4" v-if="loading">-->
<!--          <v-progress-circular-->
<!--              indeterminate-->
<!--              color="accent"-->
<!--          ></v-progress-circular>-->
<!--        </div>-->
        <div v-if="items && items.length > 0">
          <div class="container" v-for="(item1, index) in renderKey" :key="index">
            <div class="row" no-gutters>
              <div
                  v-for="(item, i) in items.slice(index * 12, (index + 1) * 12)"
                  :key="item.id"
                  class="my-2 col-md-3"
              >
                <div class="p-2">
                  <CommonItem
                      v-bind:item="items[index * 12 + i]"
                      v-bind:isCoupon="true"
                  />
                </div>
              </div>
            </div>
            <!--            <v-row  no-gutters>-->
            <!--              <v-col cols="12">-->
            <!--                <Ad />-->
            <!--              </v-col>-->
            <!--            </v-row>-->
          </div>
        </div>
        <div
            v-if="items && items.length === 0 && !loading"
            class="w-full p-2 flex justify-center text-gray-500"
        >
          <h6>No data found</h6>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.v-window__prev,
.v-window__next {
  background-color: #fff !important;
  border: 1px solid black !important;
}

.v-window-item {
  margin: 0 70px;
}

.v-icon {
  color: #ff6f3a !important;
}
</style>
<script>
import HelperMixin from "@/mixins/Helpers";
import axios from "axios";
import CommonItem from "../components/CommonItem.vue";
// import FeaturedItem from "@/components/FeaturedItem.vue";
import Ad from "@/components/Ad.vue";
import Category from "@/components/Category.vue";
import FeaturedCarousel from "@/components/FeaturedCarousel";
import Header from "@/layout/Header";

export default {
  mixins: [HelperMixin],
  data() {
    return {
      testLocation: "location",
      appHeaderKey: Math.random() + 1,
      featured: [],
      coupons: [],
      showHowManyOffers: 0,
      info: "",
      loading: true,
      // retailers: [],
      renderKey: [1],
      state: localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")).state : "",
      city: localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")).city : "",
      zipCode: localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")).zipcode : "",
      searchString: localStorage.getItem("location") ? JSON.parse(localStorage.getItem("location")).searchString : "",
    };
  },
  watch: {
    state(val) {
      console.log(val);
      val && this.fetchCoupons(val);
    },
  },
  methods: {
    goToMicrosite(logo, name, phone_no, contact_detail, lng,lat) {
      this.$router.push({name: 'Retailer', params: {
        name: name,
          logo: logo,
          phone: phone_no,
          detail: contact_detail,
          lng: lng,
          lat: lat
      }})
    },
    showModal() {
        this.$refs['my-modal'].show()
      },
    hideModal() {
        this.$refs['my-modal'].hide()
      },
    toggleModal() {
        // We pass the ID of the button that we want to return focus to
        // when the modal has hidden
        this.$refs['my-modal'].toggle('#toggle-btn')
    },
    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log(
                position.coords.latitude,
                position.coords.longitude,
                position
            );
            this.testLocation = `${position.coords.latitude} ${position.coords.longitude}`;
          },
          (error) => {
            console.log(error.message);
          }
      );
    },
    fetchCoupons() {
      axios({
        method: "get",
        url: 'http://localhost:5000/api/coupons',
        data: {
          "state": this.state,
          "city": this.city,
          "zipCode": this.zipcode,
          "searchString": this.searchString,
        },
      })
          .then((response) => {
            this.coupons = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
            this.loading = false;
          });
    },
    fetchFeaturedCoupons() {
      axios({
        method: "post",
        url: `${this.$store.state.baseURL}/api/Coupons/GetCoupons`,
        data: {
          getFeatured: true,
        },
      })
          .then((response) => {
            this.featured = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
            this.loading = false;
          });
    },
    fetchRetailers() {
      axios.get('http://localhost:5000/api/retailers')
          .then((response) => {
            this.retailers = response.data.slice(0, 4);
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.errored = true;
            this.loading = false;
          });
    },
    showMoreOffers() {
      this.renderKey.push(this.renderKey.length + 1)
    },
    // errorHandler(event) {
    //   event.target.src = `${this.$store.state.baseURL}/no_image.png`;
    // },
    getpath(id) {
      return `/retailers/retailer/${id}`;
    },
    reRenderRouterView() {
      this.routerViewKey++;
    },
    showLocationModal() {
      this.dialog = true;
    },
    hideLocationModal() {
      this.dialog = false;
    },
    reRenderAppheader() {
      this.appHeaderKey++;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.showModal();
    // this.fetchCoupons();
    // // this.fetchRetailers();
    this.locatorButtonPressed()
    // this.fetchFeaturedCoupons();
  },
  computed: {
    items() {
      return this.$store.state.items
    },
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }

      return 1;
    },
    retailers() {
      return this.$store.state.retailers.slice(0, 4)
    }
  },
  components: {
    FeaturedCarousel,
    CommonItem,
    // FeaturedItem,
    AppHeader: Header,
    Ad,
    Category
  },
};
</script>
<style>
.retailer-image {
  width: 80px;
  height: 80px;
  padding: 24.6px 7px 22.6px 8px;
  border: solid 1px #e9e9e9;
  background-color: #fff;
}

.retailer-text {
  width: 65px;
  height: 17px;
  margin: 20% 18px 23px 10px;
  opacity: 0.55;
  font-family: Lato, serif;
  font-size: 16px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.71;
  letter-spacing: -0.14px;
  text-align: left;
  color: #000;
}

.retailer-bg {
  width: 267px;
  height: 478px;
  margin: 24px 24px 1px 2px;
  padding: 13px 20px 23px 8px;
  opacity: 1;
  border-radius: 3px;
  border: solid 1px #e9e9e9;
  background-color: #fff;
}

</style>