import axios from "axios";
const Helpers = {
  methods: {
    getImageUrl(icon) {
      return require(`../assets/images/${icon}`);
    },
    getLocalCoupons(lat, lng){
      return axios.get(`${this.$store.state.baseURL}/coupons/?point=${lat},${lng}`);
    },
  },
};

export default Helpers;
