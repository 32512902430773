<template>

  <header class="bg-gray-dark">
    <div class="container mx-auto">
      <div class="row">
        <div class="flex justify-between items-stretch py-3">
          <div class="flex flex-auto items-center">
            <div class="flex-shrink-0">
              <router-link to="/" class="text-white text-2xl">
                <img
                    class="w-48"
                    src="@/assets/images/dashshop_Logo.png"
                    alt="Dash Shops"
                />
              </router-link>
            </div>
            <div class="flex-auto flex items-stretch ml-12 relative hidden sm:inline-flex">

              <v-row style="max-width: 600px;" class="flex flex-row py-0 my-0 h-10 justify-center items-center">
                <v-col class="px-0 mx-2 py-0 h-8">
                  <input
                      placeholder="Search Coupons..."
                      style="width: 455px; height: 34px; margin: 0.5px 1px 0.5px 71.2px; object-fit: contain;"
                      :value="value"
                      @input="updateValue($event.target.value)"
                      class="pl-10 border-0 py-2 pt-1 focus:outline-none text-sm w-full placeholder-gray-700 bg-white"
                  />
                </v-col>
                <v-col class="px-0 py-0 h-8">
                  <button
                      type="button"
                      class="bg-burning-orange-500 text-white px-2 hover:bg-burning-orange-600"
                      style="height: 34px;"
                      @click="onLocationSave()"
                  >
                    <svg
                        class="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      ></path>
                    </svg>
                  </button>
                </v-col>
              </v-row>
            </div>

            <button @click="toggleCategory" style="color: white; margin-right: 20px;" v-bind:class="[showCategories ? activeClass : 'hidden sm:inline-flex ml-2']"> Categories </button>
          </div>
          <div>
            <ul class="flex h-full space-x-6">
              <li class="text-white inline-flex items-center">
                <router-link to="/order/checkout">
                  <svg
                      class="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    ></path>
                  </svg>
                </router-link>
              </li>
              <li class="text-white inline-flex items-center">
                <router-link to="/notificaions">
                  <svg
                      class="w-6 h-6"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    ></path>
                  </svg>
                </router-link>
              </li>
              <li class="text-white" v-if="!locstorage.getItem('token')">
                <router-link
                    to="/login"
                    class="inline-flex items-center h-full px-6 text-sm font-semibold bg-burning-orange-500 rounded hover:bg-burning-orange-600"
                >
                  Sign In
                </router-link>
              </li>
              <li :key="logoutKey" class="text-white inline-flex items-center h-full px-6 text-sm font-semibold bg-burning-orange-500 rounded hover:bg-burning-orange-600" v-if="!!locstorage.getItem('token')" @click="logOut()">
                Log Out
              </li>
            </ul>
          </div>
        </div>
        <div class="flex-auto flex items-stretch w-full relative sm:hidden inline-flex">
          <button @click="toggleCategory" style="color: white; " v-bind:class="[showCategories ? activeClassOnMobile : 'sm:hidden inline-flex p-2 ml-2']"> Categories </button>
        </div>
        <div v-if="showCategories"  class="bg-burning-orange-500 h-24" > <Category v-bind:onHeader="true" /> </div>
      </div>
    </div>
    <div class="bg-white border-b border-gray-400">
      <div class="container mx-auto">
        <ul class="flex box-border py-0 my-0" style="width: 1087px; margin-left: 60px">
          <li class="w-1/4 flex justify-center border-l border-gray-400 py-0 my-0">
            <router-link
                to="/featured"
                class="secondary--text inline-flex  flex-wrap justify-center items-center space-x-2 py-0 w-full text-gray-600 font-semibold hover:text-gray-900"
                activeClass="text-gray-900"
            >
              <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
                ></path>
              </svg>
              <span class="secondary--text">Featured</span>
            </router-link>
          </li>
          <li class="w-1/4 flex justify-center border-l border-gray-400">
            <router-link
                to="/local"
                class="secondary--text inline-flex flex-wrap justify-center items-center space-x-2 py-0 w-full text-gray-600 font-semibold hover:text-gray-900"
                activeClass="text-gray-900"
            >
              <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                ></path>
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                ></path>
              </svg>
              <span class="secondary--text">Local</span>
            </router-link>
          </li>
          <li class="w-1/4 flex justify-center border-l border-gray-400">
            <router-link
                to="/national"
                class="secondary--text inline-flex flex-wrap justify-center items-center space-x-2 py-0 w-full text-gray-600 font-semibold hover:text-gray-900"
                activeClass="text-gray-900"
            >
              <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9"
                ></path>
              </svg>
              <span class="secondary--text">National</span>
            </router-link>
          </li>
          <li class="w-1/4 flex justify-center border-l border-r border-gray-400">
            <router-link
                to="/retailers"
                class="secondary--text inline-flex flex-wrap justify-center items-center space-x-2 py-0 w-full text-gray-600 font-semibold hover:text-gray-900"
                activeClass="text-gray-900"
            >
              <svg
                  class="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                ></path>
              </svg>
              <span class="secondary--text">Retailers</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </header>
  <!-- End of Header -->
</template>

<script>
import HelperMixin from "@/mixins/Helpers";
import Category from "@/components/Category.vue";
import axios from "axios";

export default {
  mixins: [HelperMixin],
  props:{
    callBack: { type: Function },
    searchString: String,
    value: String,
    zipcode: String
  },
  data() {
    return {
      showCategories: false,
      activeClass: "bg-burning-orange-500 p-2 pb-6 -mb-10 ml-2 focus:outline-none hidden sm:inline-flex",
      activeClassOnMobile: "bg-burning-orange-500 p-2 pb-6 -mb-4 focus:outline-none sm:hidden inline-flex p-2 ml-2",
      zipCodes: [],
      // zipcode: "",
      city: "",
      selectedState: "",
      location: {state:"", city:"", zipcode: "", searchString: ""},
      search: null,
      loading:false,
      locstorage: localStorage,
      logoutKey: 1
    };
  },
  watch: {
    search(val) {
      val && this.fetchZipCodes(val);
    },
  },
  methods:{
    toggleCategory(){
      this.showCategories = !this.showCategories
    },
    updateValue(value) {
      this.$emit('input', value)
    },
    logOut(){
        localStorage.removeItem('token');
        localStorage.removeItem('email')
        localStorage.removeItem('userId')
                localStorage.removeItem('zipCode')
        this.iogoutKey = 2
    },
    fetchZipCodes(value) {
      axios({
        method: "get",
        url: `${this.$store.state.baseURL}:8081/api/Coupons/GetZipcodes?SearchString=${value}`,
      })
        .then((response) => {
          this.loading = true;
          this.zipCodes = response.data.map(
            (d) => `${d.state}, ${d.city}, ${d.zip_code}`
          );
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
          this.loading = false;
        });
    },
    onLocationSave() {
      if(this.zipcode){
        const loc = this.zipcode.split(", ");
        this.location.state = loc[0];
        this.location.city = loc[1];
        this.location.zipcode = loc[2];
        this.location.searchString = this.searchString
        localStorage.setItem("location", JSON.stringify(this.location));
        this.callBack()
      }
    },
  },
  components: {
    Category
  }
}
</script>
