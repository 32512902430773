import { render, staticRenderFns } from "./Microsite.vue?vue&type=template&id=4d484bac&scoped=true&"
import script from "./Microsite.vue?vue&type=script&lang=js&"
export * from "./Microsite.vue?vue&type=script&lang=js&"
import style0 from "./Microsite.vue?vue&type=style&index=0&id=4d484bac&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d484bac",
  null
  
)

export default component.exports