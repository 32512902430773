<template>
  <div>
    <div class="container mx-auto pt-4 pb-8">
      <div class="flex mt-4 space-x-8">
        <div class="relative h-full sm:w-2/3 w-1/3 bg-white p-6 border">
          <div>
            <label>Contact Information</label>
            <div class="w-full py-1">
              <input
                type="text"
                class="w-full border-2 focus:outline-none focus:ring focus:border-blue-300 border-gray-200"
              />
            </div>
            <div class="mt-4">
              <label>Shipping Address</label>
              <div class="w-full py-1 flex flex-row justify-between">
                <input
                  type="text"
                  placeholder="First Name"
                  class="w-full p-1 border-2 focus:outline-none focus:ring focus:border-blue-300 border-gray-200"
                />
                <input
                  type="text"
                  placeholder="Last Name"
                  class="w-full p-1 ml-2 border-2 focus:outline-none focus:ring focus:border-blue-300 border-gray-200"
                />
              </div>
              <div class="w-full py-1">
                <input
                  type="text"
                  placeholder="Company Name (Optional)"
                  class="w-full p-1 border-2 focus:outline-none focus:ring focus:border-blue-300 border-gray-200"
                />
              </div>
              <div class="w-full py-1">
                <input
                  type="text"
                  placeholder="Address"
                  class="w-full p-1 border-2 focus:outline-none focus:ring focus:border-blue-300 border-gray-200"
                />
              </div>
              <div class="w-full py-1">
                <input
                  type="text"
                  placeholder="Apartment, suite, etc (Optional)"
                  class="w-full border-2 focus:outline-none focus:ring focus:border-blue-300 border-gray-200 p-1"
                />
              </div>

              <div class="w-full py-1">
                <input
                  type="text"
                  placeholder="City"
                  class="w-full p-1 border-2 focus:outline-none focus:ring focus:border-blue-300 border-gray-200"
                />
              </div>

              <div class="w-full py-1 flex flex-row justify-between">
                <select id="country" @change="onChange($event)" v-model="selectedCountry"
                                   class="w-full p-1 bg-white border-2 focus:outline-none focus:ring focus:border-blue-300 border-gray-200"
                    >
                  <option value="" disabled>Select country</option>
                  <option
                    v-for="(country, index) in countries"
                    :key="`country-${index}`"
                    :value= "country"
                  >
                    {{ country.country }}
                  </option>
                </select>

                <select id="state"
                   class="w-full p-1 ml-2 border-2  bg-white focus:outline-none focus:ring focus:border-blue-300 border-gray-200"
               >
                  <option value="" disabled>Select state</option>
                  <option
                    v-for="(state, index) in selectedCountry.states"
                    :key="`state-${index}`"
                    value="state"
                  >
                    {{ state }}
                  </option>
                </select>
                
                <input
                  type="text"
                  placeholder="Zip Code"
                  class="w-full p-1 ml-2 border-2 focus:outline-none focus:ring focus:border-blue-300 border-gray-200"
                />
              </div>

              <div class="w-full py-1">
                <input
                  type="text"
                  placeholder="Phone"
                  class="w-full p-1 border-2 focus:outline-none focus:ring focus:border-blue-300 border-gray-200"
                />
              </div>
            </div>
            <div class="w-full my-2 flex flex-row justify-between">
              <router-link to="/payment" class="w-full">
                <button class="w-full border-2 border-gray-200 p-1 bg-burning-orange-500 hover:bg-burning-orange-600">
                  Pickup In Store
                </button>
              </router-link>
              <button v-on:click="onChangeShowMap(true)" class="w-full ml-2 border-2 border-gray-200 p-1 bg-burning-orange-500 hover:bg-burning-orange-600">
                Delivery Service
              </button>
              <router-link to="/shippingconfirmation" class="w-full">
                <button class="w-full ml-2 border-2 border-gray-200 p-1 bg-burning-orange-500 hover:bg-burning-orange-600">
                  Ship to Address
                </button>
              </router-link>
            </div>
            <div v-if="showMap">
                <div class="w-auto p-4">
            <img
              class="w-full"
              src="@/assets/map.png"
              alt="driver's location"
            />
          </div>
          
            </div>  

            <router-link to="/retailers/retailer">
              <button class="border-2 border-gray-200 p-1 my-2">
                Return to retailer's page
              </button>
            </router-link>
          </div>
        </div>
        <div
          class="flex-auto flex flex-col bg-white border max-h-full overflow-hidden"
        >
          <div class="p-4">
            <img
              :src="getImageUrl('coupon-img-lenscrafters@2x.png')"
              alt="Versace"
            />
          </div>
          <div class="px-4 mt-2 overflow-y-auto">
            <p class="font-semibold py-2">Product Description</p>
            <hr />
            <div class="my-4">
              <div class="flex flex-row justify-between">
                <p class="font-semibold">Subtotal</p>
                <p class="font-semibold">$24.95</p>
              </div>
              <div class="flex flex-row justify-between">
                <p class="font-semibold">Subtotal</p>
                <p class="font-semibold">Calculated at next step</p>
              </div>
            </div>
            <hr />
            <div class="mt-6 flex flex-row justify-between">
              <p class="font-semibold">Total</p>
              <p class="font-semibold">$24.95</p>
            </div>
            <div v-if="showMap" class="my-2 flex flex-row justify-between">
              <p class="font-semibold">Delivery Cost</p>
              <p class="font-semibold">$8.00</p>
            </div>
            <div v-if="showMap" class="my-2 mb-6 flex flex-row justify-between">
              <p class="font-semibold">Total Cost</p>
              <p class="font-semibold">$32.95</p>
            </div>
          </div>
          <div v-if="showMap" class="w-full">
          <hr >
          <div class="flex flex-row justify-center my-4">
          <router-link to="/payment" >
          <button
                class="ml-2 w-48 border-2 border-gray-200 p-1 bg-burning-orange-500 hover:bg-burning-orange-600"
              >
                Pay Now
              </button>
          </router-link>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>
<style scoped>
</style>
<script>
import HelpersMixin from "@/mixins/Helpers";

export default {
  mixins: [HelpersMixin],
  name: "state",

  data: function () {
    return {
    selectedCountry: {},
    states: {},
    showMap: false
    };
  },
  methods: {
    setStates: function (s) {
      this.states = s;
      console.log(s)
    },
    onChange(event) {
      console.log(event.target.value)
    },
    onChangeShowMap(value){
      this.showMap = value
    }
  },
  computed: {
    countries() {
      return this.$store.state.countries;
    },
  },
};
</script>

